/*! Flickity v2.0.2
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-enabled {
  position: relative;

  &:focus {
    outline: none;
  }

  /* draggable */
  &.is-draggable {
    user-select: none;

    .flickity-viewport {
      /* stylelint-disable declaration-block-no-duplicate-properties */
      cursor: move;
      cursor: grab;
      /* stylelint-enable */

      &.is-pointer-down {
        cursor: grabbing;
      }
    }
  }
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  /* stylelint-disable declaration-block-no-duplicate-properties */
  background: color(white);
  background: hsla(0, 0%, 100%, 0.75);
  /* stylelint-enable */
  cursor: pointer;
  /* vertically center */
  transform: translateY(-50%);

  &:hover {
    background: color(white);
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 5px #09f;
  }
  &:active {
    opacity: 0.6;
  }
  &:disabled {
    opacity: 0.3;
    cursor: auto;
  }

  &.previous {
    left: 10px;
  }
  &.next {
    right: 10px;
  }

  /* stylelint-disable selector-no-type */
  svg {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
  /* stylelint-enable */

  .arrow {
    fill: #333;
  }
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -45px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;

  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;

    &.is-selected {
      opacity: 1;
    }
  }
}

/* right to left */
.flickity-rtl {
  .flickity-prev-next-button {
    &.previous {
      left: auto;
      right: 10px;
    }
    &.next {
      right: auto;
      left: 10px;
    }
  }
  .flickity-page-dots {
    direction: rtl;
  }
}
