.case-study-block__admin-view-link {
    padding: 7px;
    display: inline-block;
    line-height: 1;
    color: color(primary);
    background-color: color(white);
    position: absolute;
    top: 50.2%;
    right: 0;
    opacity: 0;
    z-index: -1;
    transform: translateY(0);
    transition: transform 0.5s, opacity 0.5s, z-index 0.5s step-end;
}

.case-study-block__inner {
    @include centered;

    display: block;
    position: relative;
    z-index: $z-low;

    width: 100%;
    height: 0;
    padding-bottom: 100%;

    opacity: 0;
    transform: translateY(30px);

    &:hover {
        .case-study-block__admin-view-link {
            transform: translateY(-100%);
            z-index: 1;
            opacity: 1;
            transition: transform 0.5s, opacity 0.5s, z-index 0.5s step-start;
        }
    }

    @media (max-width: breakpoint(500) - 1px) {
        overflow: hidden;
    }

    @media (min-width: breakpoint(500)) {
        padding-bottom: 58.3941606%;
    }

    .case-study-block--thin & {
        width: 63%;
        padding-bottom: 0;
        height: auto;
        &::before {
            content: '';
            display: block;
            padding-top: 70%;
        }
    }
}

.case-study-block__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.4s;

    @media (max-width: breakpoint(500) - 1px) {
        height: 100%;
        width: auto;
        left: 50%;
        transform: translateX(-50%);
    }

    .case-study-block--thin & {
        opacity: 0.5;
    }
}

.case-study-block__box {
    @include middle(xy, absolute);

    width: 75%;

    @media (min-width: breakpoint(500)) {
        width: 50%;
    }

    @media (min-width: breakpoint(800)) {
        width: 40%;
    }

    @media (min-width: breakpoint(1000)) {
        width: 31%;
    }

    @media (min-width: breakpoint(1400)) {
        width: 430px;
    }

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        background-color: rgba(color(primary), 0.8);

        transform-origin: 50% 0;
        transform: scaleY(0);
        opacity: 0;
    }
}

.case-study-block .squiggle-container {
    position: absolute;
    bottom: 10%;
    right: -40px;

    @media (min-width: breakpoint(1100)) {
        bottom: 15%;
    }

    @media (min-width: 1650px) {
        right: -125px;
    }

    @media (max-width: 767px) {
        display: none;
    }
}

.case-study-block__title {
    @include middle(xy, absolute);
    @include responsive-text(320px, breakpoint(1400), 45px, 120px);

    color: color(white);
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;

    /* stylelint-disable selector-no-type */
    span {
        display: inline-block;
        transform: translateY(-100%);
    }
    /* stylelint-enable selector-no-type */
}

.case-study-block__subtitle {
    @include responsive-text(320px, breakpoint(1400), 10px, 16px);

    color: color(white);
    margin-bottom: 0;
    font-family: $font-secondary;
    text-align: center;

    position: absolute;
    bottom: 17%;
    left: 0;
    width: 100%;
    overflow: hidden;

    /* stylelint-disable selector-no-type */
    span {
        display: inline-block;
        transform: translateY(-100%);
    }
    /* stylelint-enable selector-no-type */

    &::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 1px;
        width: 20px;
        background-color: color(white);
        margin-right: 10px;

        transform-origin: 0 50%;
        transform: scaleX(0);

        @media (min-width: breakpoint(600)) {
            width: 30px;
            margin-right: 20px;
        }
    }
}

.case-study-block__inner.-in-view {
    transition: transform 0.6s $ease-out, opacity 0.6s;
    opacity: 1;
    transform: none;

    .case-study-block__title {

        /* stylelint-disable selector-no-type */
        span {
            transition: transform 0.6s 0.4s $ease-out;
            transform: none;
        }
        /* stylelint-enable selector-no-type */
    }

    .case-study-block__box::before {
        transition: transform 0.6s 0.8s $ease-out, opacity 0.6s 0.8s;
        transform: none;
        opacity: 1;
    }

    .case-study-block__subtitle {

        /* stylelint-disable selector-no-type */
        span {
            transition: transform 0.6s 1.2s $ease-out;
            transform: none;
        }
        /* stylelint-enable selector-no-type */
        &::before {
            transition: transform 0.6s 1.2s $ease-out;
            transform: none;
        }
    }

    &.-settled {
        .case-study-block__box::before {
            transition: transform 0.4s $ease-out;
            transform-origin: 50% 50%;
        }

        &:hover {
            /* stylelint-disable selector-max-specificity */
            .case-study-block__img {
                opacity: 0.8;
            }
            .case-study-block__box::before {
                transform: scale(1.05);
            }
            /* stylelint-enable selector-max-specificity */
        }
    }
}

.case-study-header__columns {
    @media (min-width: breakpoint(800)) {
        display: flex;
    }
}

.case-study-header__column {
    &.-main {
        @extend %blockquote;
        padding-left: 20px;
        padding-right: 20px;

        @media (min-width: breakpoint(800)) {
            flex: 0 1 66.6666666%;
            margin-top: -11px;

            p {
                margin: 0;
            }
        }

        @media (min-width: 1400px) {
            padding-left: calc(16.666666667% + 20px);
        }
    }

    &.-details {
        @media (min-width: breakpoint(600)) {
            display: flex;
        }

        @media (min-width: breakpoint(800)) {
            display: block;
            flex: 0 1 33.333333333%;
            margin-left: 0;
            width: auto;
        }

        @media (min-width: 1400px) {
            padding-left: 16.666666667%;
        }
    }
}

.case-study-header__detail {
    @extend %trim;
    margin-bottom: $spacing / 2;
    padding: 0 20px;

    float: left;
    width: 50%;

    &:nth-child(2n+1) {
        clear: left;
    }

    @media (min-width: breakpoint(600)) {
        width: 100%;
        flex: 0 1 25%;
    }
}

.case-study-header__detail-title {
    margin-bottom: 5px;
    line-height: 30px;
}

.case-study-header__detail-value {
    color: color(overcast);
    line-height: 1.4;
    font-size: rem(17px);

    /* stylelint-disable selector-no-type */
    a {
        color: color(overcast);
        border-bottom: 0;
        transition: color 0.3s;

        &:hover {
            color: color(nimbus);
        }
    }
    /* stylelint-enable selector-no-type */
}

.case-study-header__detail-value--list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        display: inline;

        &:not(:last-child)::after {
            content: ', ';
        }
    }
}

.case-study-stats {
    text-align: center;
    color: color(primary);
    position: relative;
}

.case-study-stats__title {
    margin-bottom: $spacing;

    @media (min-width: breakpoint(800)) {
        margin-bottom: $spacing * 2;
    }

    @media (min-width: breakpoint(1200)) {
        margin-bottom: $spacing * 3;
    }
}

.case-study-stats__columns {
    position: relative;
    display: flex;
    flex-direction: column;
    background: rgba(#e3dfec, 0.2);
    padding-top: 12%;
    padding-bottom: 12%;

    &::after {
        @extend %squiggle;

        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
    }

    @media (min-width: breakpoint(800)) {
        flex-direction: row;
        justify-content: space-around;
    }
}

.case-study-stats__column {
    padding: 20px;

    @media (min-width: breakpoint(600)) {
        padding: 40px 20px;
    }

    @media (min-width: breakpoint(800)) {
        flex: 0 1 33.333333333%;
        padding: 0 20px;
    }
}

.case-study-stats__value {
    margin: 0;
    font-feature-settings: 'tnum';
}

.case-study-stats__btn {
    @extend %caption;

    display: inline-block;
    right: 20px;
    bottom: 20px;
    position: absolute;
    color: color(primary);

    @media (min-width: breakpoint(800)) {
        right: 70px;
        bottom: 70px;
    }
}

.case-study-stats__btn-arrow {
    display: inline-block;
    vertical-align: middle;
    width: 9px;
    height: 20px;
    margin-left: 20px;
}


.case-study-next {
    text-align: center;
}

.case-study-next__title {
    color: color(overcast);
    margin-bottom: $spacing;
}

.case-study-next__link {
    line-height: 1.4;
    color: inherit;
}




.case-studies {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.case-studies-nav {
    list-style: none;
    margin: 0;
    flex: 1 0 260px;
    text-align: center;
    line-height: 1.5;

    /* stylelint-disable selector-no-type */
    a {
        opacity: 0.3;
        transition: opacity 0.3s;
        color: #fff;

        /* stylelint-disable selector-no-qualifying-type */
        &[aria-selected] {
            opacity: 1;
        }
        /* stylelint-enable selector-no-qualifying-type */

        &:not([aria-selected]):hover {
            opacity: 0.5;
        }
    }
    /* stylelint-enable selector-no-type */
}

.case-study-item__title {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.case-study-item__link {
    display: block;
    opacity: 0.8;
}

.case-study-items {
    position: relative;
    width: 100%;
    flex: 0 1 800px;

    &::before {
        content: '';
        display: block;
        padding-top: 100%;
    }
}

.case-study-item {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* stylelint-disable selector-no-type */
    img {
        max-width: 100%;
    }
    /* stylelint-enable selector-no-type */
}

.case-study-item--top {
    z-index: 1;
}

.case-study-item__img {
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: color(primary);
        transform-origin: bottom;
        transition: transform 0.45s $ease-out;

        .case-study-item--active & {
            transform: scaleY(0);
        }
        .case-study-item--top:not(.case-study-item--active) & {
            transform-origin: top;
            //transition-timing-function: ease-in;
            //transition-duration: 0.4s;
        }
    }
}
