.eczar {
  font-family: $font-eczar;
}

.events__month-container {
  @include cf;

  &:first-child {
    hr {
      display: none;
    }
  }
}

.events__date {
  font-size: rem(32px);
  margin-bottom: 0.3rem;

  @media (max-width: 1400px) {
    font-size: rem(26px);
  }
}

.events__title {
  margin: 0;
  margin-bottom: 2rem;
  font-size: rem(80px);
  overflow: hidden;

  @media (max-width: 1400px) {
    font-size: rem(60px);
  }
}

.events__subtitle {
  margin: 0;
  font-size: rem(18px);
  overflow: hidden;

  @media (max-width: 1400px) {
    font-size: rem(16px);
  }
}

.events__month-heading {
  clear: both;
  padding-top: 6%;

  hr {
    border: 0;
    height: 4px;
    background-color: color(nimbus);
    margin: 0 10px;
  }

  h2 {
    font-size: rem(18px);
    text-align: center;
    margin: 2rem 0;
  }
}

.events-item {
  .events-item__inner {
    background-color: #8b61da;
    color: #fff;

    .events-item__date,
    .events-item__network {
      color: #fff;
    }
    .events-item__network {
      fill: #fff;
    }
  }

  &:nth-child(4n + 2),
  &:nth-child(4n + 5),
  &:nth-child(6n + 3) {
    .events-item__inner {
      background-color: color(martinique);

      .body--martinique & {
        background-color: color(white);
        color: #8b61da;

        .events-item__date,
        .events-item__network,
        .events-item__heading {
          color: #8b61da;
        }
        .events-item__network {
          fill: #8b61da;
        }
      }
    }
  }

  &:nth-child(4n + 2),
  &:nth-child(4n + 3) {
    .events-item__inner {
      background-color: #e8dff7;
      color: #8b61da;

      .body--martinique & {
        .events-item__heading {
          color: #8b61da;
        }
      }

      .events-item__date,
      .events-item__network {
        color: #8b61da;
      }
      .events-item__network {
        fill: #8b61da;
      }
    }
  }

  @media (max-width: 599px) {
    max-width: 410px;
    margin: 0 auto;
    float: none;
  }
}

.events-item__inner {
  transition: transform 0.2s ease-in-out;

  > * {
    pointer-events: none;
  }

  &:hover {
    transform: scale(0.97);
  }
}

.events-item__body {
  transition: transform 0.1s ease-out;
  margin-top: 0;
}

.events__content-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.events-item__date {
  display: inline-block;
  font-size: rem(22px);
  line-height: 1;
  margin-bottom: 10%;

  @media (max-width: 1400px) {
    font-size: rem(18px);
  }
}

.events-item__heading {
  line-height: 1;
  font-size: rem(32px);
  font-family: $font-eczar;

  .grid__inner:hover & {
    opacity: 1;
  }

  @media (max-width: 1400px) {
    font-size: (18px);
  }
}

.events-item__squiggle {
  background: url('../img/svg/squiggle-repeating.svg') top left repeat-x;
  background-size: cover;
  width: 90px;
  height: 11px;
  display: block;
  margin: 0 auto;

  @media (max-width: 1400px) {
    width: 70px;
    height: 9px;
  }
}

.events-item__network {
  text-align: left;
  text-transform: capitalize;
  left: 8.7%;
  bottom: 7.1%;
  max-width: 82%;

  @media (max-width: 1400px) {
    font-size: rem(11px);
    height: 17px;
  }
}

.events-item__pin-icon {
  width: 11px;
  height: 15px;
  float: left;
  margin-right: 10px;
  position: relative;
  top: 5px;

  svg {
    width: 11px;
    height: 15px;
  }

  @media (max-width: 1400px) {
    top: 2px;
  }
}

.events-item__venue-name {
  width: 82%;
  display: inline-block;
  line-height: 1.1;
}

.events-item__reply-icon {
  width: 19px;
  float: right;

  svg {
    width: 100%;
    height: auto;
  }
}
