.phones {
  @media (max-width: (breakpoint(700) - 1px)) {
    position: relative;
    display: inline-block;
    background: #e7e7e7;
    border-radius: 50px;
    width: 100%;

    &::before {
      content: '';
      display: block;
      padding-top: (750 / 360) * 100%;
    }
  }
}

.phones-inner {
  @media (min-width: breakpoint(700)) {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: (breakpoint(700) - 1px)) {
    position: absolute;
    top: (95 / 750) * 100%;
    left: (20 / 360) * 100%;
    width: (320 / 360) * 100%;
    height: auto;
    white-space: nowrap;
    overflow: hidden;

    &::after {
      content: 'flickity';
      display: none;
    }
  }
}

.phone {
  display: inline-block;

  @media (max-width: (breakpoint(700) - 1px)) {
    width: 100%;
  }

  @media (min-width: breakpoint(700)) {
    position: relative;
    display: inline-block;
    background: #e7e7e7;
    border-radius: 30px;
    flex: 0 0 28%;

    &::before {
      content: '';
      display: block;
      padding-top: (750 / 360) * 100%;
    }
  }

  @media (min-width: breakpoint(1200)) {
    border-radius: 50px;
  }

  /* stylelint-disable selector-no-type */
  img {
    height: auto;
    @media (max-width: (breakpoint(700) - 1px)) {
      width: 100%;
    }
    @media (min-width: breakpoint(700)) {
      position: absolute;
      top: (95 / 750) * 100%;
      left: (20 / 360) * 100%;
      width: (320 / 360) * 100%;
    }
  }
  /* stylelint-enable selector-no-type */
}
