/**
 * Nav specific stuff
 * Note: despite indentation, these are *not* nested & shouldn't be
 */

.site-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  height: 80px;

  @media (min-width: 950px) {
    height: auto;
    padding-top: 50px;
    background-color: transparent;
    font-family: $font-secondary;
  }

  @media (min-width: 1400px) {
    padding-top: 70px;
  }

  @media (min-width: 1800px) {
    padding-top: 100px;
  }

  .body--events_index & {
    @media (min-width: 950px) {
      padding-top: 80px;
    }

    @media (min-width: 1800px) {
      padding-top: 100px;
    }
  }
}

.site-nav__list {
  @include clean-list;
  position: relative;
  margin: 0;

  will-change: transform, opacity;

  @media (min-width: 950px) {
    display: block;
    left: 0;
    transition: transform 0.4s 0.4s $ease-out, opacity 0.4s 0.4s;

    /* stylelint-disable selector-no-qualifying-type */
    body.-page-loading & {
      opacity: 0;
      pointer-events: none;
      // transform: translateY(-40px);
      transition: transform 0.4s $ease-out, opacity 0.4s;
    }
    /* stylelint-enable selector-no-qualifying-type */

    .site-nav--contact-page & {
      display: none;
    }
  }

  @media (max-width: 949px) {
    background: color(primary);
    position: fixed;
    will-change: transform, opacity;
    overflow-y: auto;
    overflow-x: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 100px;
    padding-bottom: 100px;

    display: flex;
    flex-direction: column;

    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.4s $ease-out, opacity 0.4s;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      opacity: 0;
      transition: all 0.7s 0.6s $ease-out;
    }

    &::before {
      background: url('../img/svg/curve-black.svg') top left no-repeat;
      width: 307.6px * 0.8;
      height: 131.21px * 0.8;
      background-size: cover;
      top: 30%;
      left: 0;
      margin-left: -130px;
      transform: rotate(180deg) translateX(40px);
      transition-delay: 0.4s;

      @media (min-width: 600px) {
        width: 307.6px * 1;
        height: 131.21px * 1;
      }
    }

    &::after {
      border-radius: 50%;
      background: color(primary-light);
      width: 320px;
      height: 320px;
      top: 40%;
      right: 0;
      margin-right: -140px;
      transform: translateX(40px);

      @media (min-width: 700px) {
        width: 400px;
        height: 400px;
        top: 50%;
        margin-right: -170px;
      }
    }

    .site-nav.-visible & {
      transform: none;
      opacity: 1;

      &::before {
        opacity: 0.1;
        transform: rotate(180deg);
      }
      &::after {
        opacity: 0.3;
        transform: none;
      }
    }
  }

  @media (min-width: 950px) and (max-width: 1200px) {
    .site-nav:not(.-in-v):not(.-out-v) & {
      padding-left: 60px;
      padding-right: 158px;
    }
  }
}

.site-nav__squiggle {
  @media (min-width: 950px) {
    display: none;
  }
}
.site-nav .big-squiggle {
  position: absolute;
  backface-visibility: hidden;
  z-index: $z-high;
  bottom: 10%;
  left: 0;
  margin-left: -25px;
  width: 120px;
  opacity: 0;
  transition: opacity 0.7s 0.6s;

  div {
    transform: translate3d(-33%, 0, 0);
    transition: transform 0.7s 0.55s $ease-out;
  }

  .-mobile-nav-active & {
    opacity: 1;
    div {
      transform: translate3d(0, 0, 0);
    }
  }

  @media (min-width: 600px) {
    width: 180px;
  }
  @media (min-width: 700px) {
    margin-left: 60px;
  }

  @media (min-width: 950px) {
    display: none;
  }
}

.site-nav__item {
  display: inline-block;
  padding: 0 25px;

  @media (max-width: 949px) {
    display: block;
    padding: 10px 30px;
    transform: translateY(30px);
    opacity: 0;
    transition: transform 0.3s 0.3s $ease-out, opacity 0.3s 0.3s;

    .site-nav.-visible & {
      transform: none;
      opacity: 1;
    }

    @for $i from 0 through 7 {
      &:nth-child(#{$i + 2}) {
        transition-delay: #{0.35s + ($i * 0.05s)}, #{0.35s + ($i * 0.05s)};
      }
    }

    &:first-child {
      margin-top: auto;
    }
    &:nth-last-child(2) {
      margin-bottom: auto;
    }
  }

  @media (min-width: 950px) {
    opacity: 0;
    transition: opacity 0.6s;

    @for $i from 0 through 7 {
      &:nth-child(#{$i + 1}) {
        transition-delay: #{0.5s + ($i * 0.1s)};

        .body--home & {
          transition-delay: #{1s + 0.5s + ($i * 0.1s)};
        }
      }
    }

    .fonts-loaded.hero-loaded &,
    body:not(.landing) & {
      opacity: 1;
    }
  }

  .site-nav:not(.-in-v):not(.-out-v) &.-contact {
    @media (min-width: 950px) {
      position: absolute;
      top: -12px;
      right: 40px;
      padding: 0;

      @media (min-width: 1200px) {
        top: -20px;
      }

      /* commented out for stylelint rule: declaration-block-no-ignored-properties */
      /* vertical-align: top; */
    }
    @media (min-width: 1400px) {
      right: 60px;
    }
  }
}

.site-nav__link {
  display: inline-block;
  color: color(white);
  position: relative;
  transition: color 0.4s;

  @extend %charlie-size;

  @media (max-width: 949px) {
    line-height: 1;
  }

  @media (min-width: 950px) {
    font-size: rem(14px);

    span:not(.site-nav__number)::after {
      content: '';
      display: block;
      background-color: color(primary);
      height: 1px;

      transform-origin: left;
      transform: scaleX(0);
      transition: transform 0.2s linear;

      .body--martinique & {
        background-color: color(white);
      }
    }
  }

  @media (min-width: 1200px) {
    font-size: rem(16px);
  }

  /* stylelint-disable selector-max-specificity */
  .site-nav:not(.-in-v):not(.-out-v) &:hover,
  .site-nav:not(.-in-v):not(.-out-v) &.-current {
    span::after {
      transform: none;
    }
  }

  .site-nav:not(.-in-v):not(.-out-v) &.-contact,
  &.-close {
    @media (min-width: 950px) {
      // most of the stuff from the .btn class
      opacity: 0;
      appearance: none;
      display: inline-block;
      vertical-align: top;
      overflow: hidden;

      font-size: rem(16px);
      text-align: center;

      padding: 12px 0;
      width: 118px;

      border: 0;
      outline: 0;

      background-color: transparent;
      background-size: 100% 200%;
      background-repeat: no-repeat;
      background-position: 0 0;

      transition: color $trans-speed, background-position $trans-speed,
        box-shadow $trans-speed, opacity 0.4s;

      &:hover,
      &:focus {
        box-shadow: inset 0 0 0 2px color(primary);
        color: #fff;
      }

      &:hover {
        background-position: 0 100%;
      }

      span::after {
        display: none;
      }
    }

    @media (min-width: 1200px) {
      padding: 22px 0;
      width: 158px;
    }
  }
  .site-nav:not(.-in-v):not(.-out-v) &.-contact {
    @media (min-width: 950px) {
      color: #fff;
      background-image: linear-gradient(
        to bottom,
        transparent 0%,
        transparent 50%,
        color(nimbus) 50%,
        color(nimbus) 100%
      );
      box-shadow: inset 0 0 0 2px rgba(color(white), 0.3);

      &:hover,
      &:focus {
        box-shadow: inset 0 0 0 2px color(nimbus);
      }
    }
  }
  /* stylelint-enable selector-max-specificity */

  &.-close {
    position: absolute;
    z-index: 4;

    @media (max-width: 949px) {
      top: 25px;
      right: 26px;
      width: 30px;
      height: 30px;
      appearance: none;
      padding: 0;
      margin: 0;
      border: 0;
      background: none;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 20px;
        height: 2px;
        background: color(nimbus) !important; /* stylelint-disable-line */
        top: 14px;
        left: 5px;
        transform-origin: 50% 50%;
      }

      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }

      span {
        display: none;
      }
    }

    @media (min-width: 950px) {
      top: 38px;
      right: 40px;
      color: color(primary);
      background-image: linear-gradient(
        to bottom,
        transparent 0%,
        transparent 50%,
        color(primary) 50%,
        color(primary) 100%
      );
      box-shadow: inset 0 0 0 2px rgba(color(primary), 0.3);

      &:hover,
      &:focus {
        box-shadow: inset 0 0 0 2px color(primary);
      }
    }
    @media (min-width: 1100px) {
      color: #fff;
      background-image: linear-gradient(
        to bottom,
        transparent 0%,
        transparent 50%,
        color(nimbus) 50%,
        color(nimbus) 100%
      );
      box-shadow: inset 0 0 0 2px rgba(color(white), 0.3);

      &:hover,
      &:focus {
        box-shadow: inset 0 0 0 2px color(nimbus);
      }
    }

    @media (min-width: 1200px) {
      top: 30px;
    }

    @media (min-width: 1400px) {
      top: 50px;
      right: 60px;
    }

    @media (min-width: 1800px) {
      top: 79px;
    }
  }
  &.-visible {
    /* stylelint-disable declaration-no-important */
    opacity: 1 !important;
    z-index: 1 !important;
    /* stylelint-enable declaration-no-important */
  }
}

.site-nav__number {
  display: inline-block;
  text-align: center;
  width: 21px;
  height: 21px;
  position: absolute;
  top: -4px;
  right: -25px;
  font-size: rem(13px);
  line-height: 19px;
  border-radius: 50%;
  background-color: color(primary);

  @media (min-width: 768px) {
    top: -20px;
    right: -20px;
  }

  @media (max-width: 1199px) {
    display: none;
  }
}

.nav-toggle {
  display: block;
  width: 32px;
  height: 20px;
  position: absolute;
  z-index: 1;
  top: 30px;
  right: 30px;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    height: 2px;
    background: color(white);

    transform-origin: right;
  }

  &::before {
    width: 25px;
    top: 0;
  }

  &::after {
    width: 20px;
    bottom: 0;
  }

  @media (min-width: 950px) {
    display: none;
  }

  /* stylelint-disable selector-no-type */
  span:not(.sr-only) {
    display: block;
    position: absolute;
    top: 9px;
    right: 0;
    width: 32px;
    height: 2px;
    background: color(white);
  }
  /* stylelint-enable selector-no-type */
}

.body--home,
.body--dark {
  .site-nav__link {
    &::after {
      background-color: color(white);
    }
  }
}
.body--white,
.body--cloud,
.body--shapes {
  &:not(.-mobile-nav-active) {
    .site-nav {
      background-color: color(white);

      @media (min-width: 950px) {
        background-color: transparent;
      }
    }
    .nav-toggle {
      /* stylelint-disable selector-no-type */
      &::before,
      &::after,
      span:not(.sr-only) {
        background: color(primary);
      }
      /* stylelint-enable selector-no-type */
    }
  }
}

/**
 * Nav toggle transition
 */
/* stylelint-disable time-no-imperceptible */
.nav-toggle.-active {
  &::before {
    animation: nav-toggle-top-1 0.3s forwards,
      nav-toggle-top-2 0.3s 0.3s forwards;
  }
  &::after {
    animation: nav-toggle-top-1 0.3s 0.1s forwards,
      nav-toggle-bottom 0.3s 0.4s forwards;
  }
  /* stylelint-disable selector-no-type */
  span:not(.sr-only) {
    animation: nav-toggle-middle 0.3s 0.2s forwards;
  }
  /* stylelint-enable selector-no-type */
}
.nav-toggle.-out {
  &::before {
    opacity: 1;
    transform: translate(-18px, 2px) rotate(-135deg) scaleX(0.8);
    animation: nav-toggle-top-2 0.3s forwards reverse,
      nav-toggle-top-1 0.3s 0.3s forwards reverse;
  }
  &::after {
    opacity: 1;
    transform: translate(-18px, -2px) rotate(135deg) scaleX(1);
    animation: nav-toggle-bottom 0.3s 0.1s forwards reverse,
      nav-toggle-top-1 0.3s 0.4s forwards reverse;
  }
  /* stylelint-disable selector-no-type */
  span:not(.sr-only) {
    transform: translateX(-80%) scaleX(0);
    opacity: 0;
    animation: nav-toggle-middle 0.3s 0.2s forwards reverse;
  }
  /* stylelint-enable selector-no-type */
}
/* stylelint-enable time-no-imperceptible */

@keyframes nav-toggle-top-1 {
  from {
    transform: none;
    opacity: 1;
  }
  to {
    transform: translateX(-120%) scaleX(0);
    opacity: 0;
  }
}
@keyframes nav-toggle-top-2 {
  from {
    opacity: 0;
    transform: translate(-18px, 2px) rotate(-135deg) scaleX(0);
  }
  to {
    opacity: 1;
    transform: translate(-18px, 2px) rotate(-135deg) scaleX(0.8);
  }
}
@keyframes nav-toggle-bottom {
  from {
    opacity: 0;
    transform: translate(-18px, -2px) rotate(135deg) scaleX(0);
  }
  to {
    opacity: 1;
    transform: translate(-18px, -2px) rotate(135deg) scaleX(1);
  }
}
@keyframes nav-toggle-middle {
  from {
    transform: none;
    opacity: 1;
  }
  to {
    transform: translateX(-80%) scaleX(0);
    opacity: 0;
  }
}

@media (min-width: 950px) {
  .site-nav.-out-h {
    animation: navFadeOut 0.4s forwards;
  }
  .site-nav.-in-h {
    animation: navFadeIn 0.4s forwards;
  }

  .site-nav.-in-v,
  .site-nav.-out-v {
    text-align: right;
    line-height: 1.4;
    left: auto;
    right: -10px;
    width: auto;

    @media (min-width: 1200px) {
      right: 25px;
    }

    @media (min-width: 1800px) {
      right: 65px;
    }

    @media (min-width: 2120px) {
      right: calc((50% - 800px - 130px) / 2);
    }

    .site-nav__item {
      display: list-item;

      @for $i from 1 through 7 {
        &:nth-child(#{$i + 1}) span {
          animation-delay: #{$i * 0.05s};
        }
      }
    }

    .site-nav__link {
      overflow: hidden;

      span {
        display: inline-block;
        transform: translateX(100%);
        animation: navLinkSlideIn 0.2s $ease-out forwards;
      }
    }
  }

  .site-nav.-out-v {
    .site-nav__item {
      &:nth-child(8) span {
        animation-delay: 0s;
      }
      &:nth-child(7) span {
        animation-delay: 0.05s;
      }
      &:nth-child(6) span {
        animation-delay: 0.1s;
      }
      &:nth-child(5) span {
        animation-delay: 0.15s;
      }
      &:nth-child(4) span {
        animation-delay: 0.2s;
      }
      &:nth-child(3) span {
        animation-delay: 0.25s;
      }
      &:nth-child(2) span {
        animation-delay: 0.3s;
      }
      &:nth-child(1) span {
        animation-delay: 0.35s;
      }
    }
    .site-nav__link {
      span {
        transform: translateX(0);
        animation: navLinkSlideOut 0.2s $ease-out forwards;
      }
    }
  }
}

@keyframes navFadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-40px);
  }
}

@keyframes navFadeIn {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes navLinkSlideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes navLinkSlideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@media (min-width: 950px) {
  .site-nav.-in-v,
  .site-nav.-out-v {
    .site-nav__link {
      color: #a0a0a0;
      @include on-dark {
        color: color(lilywhite);
      }

      // .body--case_studies_index &,
      .body--products_index &,
      .body--events__index & {
        color: color(white);

        &::after {
          border-color: color(white);
        }

        &:hover {
          color: color(white);
        }
      }
    }
  }
  .site-nav.-in-v {
    .site-nav__item {
      overflow: visible;
    }

    .site-nav__link {
      position: relative;

      .site-nav__number {
        display: none;
      }

      &::after {
        content: '';
        border-top: 1px solid #333;
        @include on-dark {
          border-color: color(white);
        }
        width: 0;
        display: inline-block;
        transform: translateY(-4px);
        transition: all 0.2s $ease-out;
      }

      &:hover {
        color: #333;
        @include on-dark {
          color: color(white);
        }

        &::after {
          margin-left: 9px;
          width: 15px;
        }
      }
    }
  }
}
