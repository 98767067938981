.icon-list {
  list-style: none;
  margin: 0 -30px;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 700px) {
    margin: 0;
  }

  @media (max-width: 699px) {
    border-top: 1px solid rgba(#000, 0.05);
  }

  li {
    width: 100%;
    flex: 0 0 auto;

    @media (min-width: 700px) {
      width: 50%;
    }

    @media (max-width: 699px) {
      border-bottom: 1px solid rgba(#000, 0.05);
      padding: 35px 30px;
    }

    &:nth-child(even) {
      @media (min-width: 700px) {
        border-left: 1px solid rgba(#000, 0.05);
      }

      @media (min-width: 700px) {
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          height: 1px;
          background: rgba(#000, 0.05);
        }
      }

      div {
        margin-left: auto;
      }
      svg,
      span {
        transition-delay: 0.5s, 0.5s;
      }
      h3 {
        transition-delay: 0.6s, 0.6s;
      }
      p {
        transition-delay: 0.7s, 0.7s;
      }
    }
  }

  svg,
  h3,
  p,
  span {
    transform: translateY($fade-up-distance-mobile);
    opacity: 0;
    transition: transform 0.4s $ease-out, opacity 0.4s;

    @media (min-width: 600px) {
      transform: translateY($fade-up-distance);
    }
  }

  span {
    display: block;
  }

  svg {
    fill: color(primary);
  }

  h3 {
    margin-top: 40px;
    transition-delay: 0.1s, 0.1s;
  }

  p {
    transition-delay: 0.2s, 0.2s;
  }

  div {
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: 700px) {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    @media (min-width: 1100px) {
      width: 66.6666666%;
      margin-top: 20%;
      margin-bottom: 20%;
    }

    /* stylelint-disable-next-line selector-no-universal */
    > *:last-child {
      margin-bottom: 0;
    }

    /* stylelint-disable selector-no-qualifying-type */
    &.-in-view {
      svg,
      h3,
      p,
      span {
        transform: none;
        opacity: 1;
      }
    }
    /* stylelint-enable selector-no-qualifying-type */
  }
}

.icon-list-container hr {
  border: 0;
  border-top: 1px solid rgba(#000, 0.05);
  margin: 0;
}
