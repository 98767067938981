/*------------------------------------*\
    #FONTS
\*------------------------------------*/
/* stylelint-disable value-keyword-case */
$font-primary: 'Graphik', Helvetica, Arial, sans-serif;
$font-secondary: 'GT-Pressura-Mono', Courier, monospace;
$font-tertiary: 'Calluna', Times, serif;
$font-eczar: 'Eczar', serif;
/* stylelint-enable value-keyword-case */

$base-font-family: $font-primary;
$base-font-size: 18px; // should be the default font size of textarea/body content
$base-line-height: 40px; // should be the default line height of textarea/body content

$spacing: ($base-line-height / $base-font-size) * 1rem;

$fade-duration: 0.7s;
$fade-up-distance: 40px;
$fade-up-distance-mobile: 20px;

/*------------------------------------*\
    #COLOURS
\*------------------------------------*/
$colours: (
    primary: #8b5cdd,
    primary-light: #d1bef1,
    primary-dark: #3e3750,
    cloud: #f2f1f1,
    pastel: #e3dfec,
    overcast: #a0a0a0,
    dolphin: #6c6978,
    inkblot: #2d283e,
    nimbus: #333,
    lilywhite: #eaeaea,
    whitesmoke: #f5f5f5,
    white: #fff,
    martinique: #3b3454,
);

@function color($color) {
    @return map-get($colours, $color);
}


$body-text: color(nimbus);





/*------------------------------------*\
    #ANIMATIONS AND TRANSITIONS
\*------------------------------------*/
$trans-speed: 0.3s;

$ease-out: cubic-bezier(0.42, 0.01, 0.23, 1);




/*------------------------------------*\
    #BREAKPOINTS
\*------------------------------------*/
$breakpoints: (
    400: 400px,
    500: 500px,
    600: 600px,
    700: 700px,
    800: 800px,
    1000: 1000px,
    1100: 1100px,
    1200: 1200px,
    1400: 1400px // max-width of the .site-container is 1410px
);

@function breakpoint($breakpoint) {
    @return map-get($breakpoints, $breakpoint);
}




/*------------------------------------*\
    #Z-INDEXES
\*------------------------------------*/
$z-low: 2;
$z-medium: 4;
$z-high: 6;

$z-scroller-page: 50;
$z-header: 100;




/*doc
---
title: Colours
name: colours
category: colours
---

<ul class="styleguide-colours cf">
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -primary"></div>
        <div class="styleguide-colours__name">#8b5cdd - primary</div>
    </li>
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -primary-dark"></div>
        <div class="styleguide-colours__name">#3e3750 - primary-dark</div>
    </li>
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -primary-light"></div>
        <div class="styleguide-colours__name">#d1bef1 - primary-light</div>
    </li>
</ul>
<br><br>
<h4>Greys</h4>
<ul class="styleguide-colours cf">
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -pastel"></div>
        <div class="styleguide-colours__name">#e3dfec - pastel</div>
    </li>
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -cloud"></div>
        <div class="styleguide-colours__name">#f2f1f1 - cloud</div>
    </li>
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -overcast"></div>
        <div class="styleguide-colours__name">#a0a0a0 - overcast</div>
    </li>
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -dolphin"></div>
        <div class="styleguide-colours__name">#6c6978 - dolphin</div>
    </li>
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -inkblot"></div>
        <div class="styleguide-colours__name">#2d283e - inkblot</div>
    </li>
    <li class="styleguide-colours__item">
        <div class="styleguide-colours__colour -nimbus"></div>
        <div class="styleguide-colours__name">#333333 - nimbus</div>
    </li>
</ul>

*/
