/*doc
---
title: Forms
name: forms
category: forms
---

The class of `<form>` should be .site-form

```html_example
<form class="site-form">
    <div class="input text required">
        <label for="first_name">First name</label>
        <input type="text" name="first_name" id="first_name" />
    </div>
    <div class="input text required">
        <label for="surname">Surname</label>
        <input type="text" name="surname" id="surname" />
    </div>
    <div class="input text required">
        <label for="email">Email</label>
        <input type="email" name="email" id="email" />
    </div>
    <div class="input select">
        <select name="why" id="why">
            <option value="" disabled selected>I am interested in...</option>
            <option value="val1">Lorem</option>
            <option value="val2">Ipsum</option>
            <option value="val3">Dolor Sit</option>
            <option value="val4">Amet</option>
            <option value="val5">Consectetur</option>
        </select>
    </div>
    <div class="input textarea required">
        <label for="message">Your message</label>
        <textarea name="message" id="message" rows="4"></textarea>
    </div>
    <div class="submit">
        <button class="btn -primary">Submit.</button>
    </div>
</form>
```
*/

/* stylelint-disable selector-no-type */
/* stylelint-disable selector-no-qualifying-type */
.site-form {
    position: relative;

    .input {
        position: relative;
        margin-bottom: $spacing;

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
        }

        &::before {
            background: color(cloud);
        }

        &::after {
            background: color(primary);
            transform-origin: left;
            transform: scaleX(0);
            transition: transform 0.3s linear;
        }

        label,
        input,
        textarea {
            display: block;
            width: 100%;
        }

        label {
            position: absolute;
            top: 0;
            left: 0;
            line-height: 70px;

            transform-origin: 0 100%;
            transition: transform $trans-speed, color $trans-speed;
            cursor: text;
        }

        input,
        textarea {
            // iOS webkit defaults
            appearance: none;
            border: 0;
            border-radius: 0;
            font-size: 16px;

            line-height: 1;
            height: 70px;
            padding: 0;
            color: color(primary);
            background: none;

            &:focus {
                outline: 0;
            }

            /* stylelint-disable selector-max-specificity */
            &.form-error:focus,
            &.form-error.-has-focused {
                ~ .error-message {
                    opacity: 0;
                }
            }
            /* stylelint-enable selector-max-specificity */
        }

        &.textarea {
            textarea {
                min-height: 150px;
                padding: 26px 0 0;
            }
        }

        &.-is-active {
            &::after {
                transform: none;
            }
            label {
                transform: translateY(-70%) scale(0.8);
                color: color(overcast);
                z-index: -1;
                pointer-events: none;
            }
        }

        &.-has-text {
            label {
                opacity: 0;
            }
        }
    }

    .error-message {
        @extend %caption;
        color: #a0a0a0;
        position: absolute;
        top: 16px;
        pointer-events: none;
        opacity: 1;
        transition: opacity 0.2s;
    }
}
/* stylelint-enable selector-no-qualifying-type */

.site-form--light {
    color: color(white);

    .input {
        &::before {
            background: rgba(color(white), 0.3);
        }

        input,
        textarea {
            color: #fff;
        }
    }
}

.site-form__thanks {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    margin: 0;
    opacity: 0;
    animation: formThanks 0.4s 0.4s $ease-out forwards;
    text-align: center;
    transition: 0.3s opacity;
}
.site-form__thanks--hide {
    opacity: 0;
}

@keyframes formThanks {
    to {
        opacity: 1;
    }
}

.site-form--sent {
    .contact--active & {
        opacity: 1;
        transition-delay: 0.4s;
    }

    .inputs,
    .submit {
        pointer-events: none;
    }
    .inputs {
        animation: formInputsUp 0.4s $ease-out forwards;
    }
    .submit {
        animation: formSubmitDown 0.4s $ease-out forwards;
    }
}

@keyframes formInputsUp {
    to {
        transform: translateY(-40px);
        opacity: 0;
    }
}

@keyframes formSubmitDown {
    to {
        transform: translateY(40px);
        opacity: 0;
    }
}

/* .site-form__errors {
    @include clean-list;

    margin-bottom: $spacing;
    background-color: color(pastel);
    padding: 20px;
}

.site-form__error {
    @extend %caption;
} */
/* stylelint-enable selector-no-type */
