/*doc
---
title: Headings
name: headings
category: Headings
---


```html_example
<h1>Heading 1 - alpha</h1>
<h2>Heading 2 - bravo</h2>
<h3>Heading 3 - charlie</h3>
<h4>Heading 4 - delta</h4>
<h5>Heading 5 - echo</h5>
<h6>Heading 6 - foxtrot</h6>
```

*/

/* stylelint-disable selector-no-type, selector-no-qualifying-type */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    line-height: 1.2;
    margin: 3em 0 1.7em;
}

%alpha,
.alpha {
    @include responsive-text(320px, breakpoint(1400), 40px, 90px);
    line-height: 1.2;
}

%bravo,
.bravo {
    @include responsive-text(320px, breakpoint(1400), 36px, 60px);
    line-height: 1.2;
    margin: 2em 0 1em;
}

%charlie-size {
    @include responsive-text(320px, breakpoint(1400), 25px, 44px);
}

%charlie,
.charlie {
    @extend %charlie-size;
    line-height: 1.2;

    &.-looser {
        line-height: 1.7;
    }
}

/* no-descending-specificity stylelint rule recommended this be moved below h1, h2, h3 */,
%alpha-heading,
.alpha-heading {
    h1,
    h2,
    h3 {
        @extend %alpha;
    }
}

%delta,
.delta {
    @include responsive-text(320px, breakpoint(1400), 24px, 36px);
    line-height: 1.2;

    &.-looser {
        line-height: (50 / 36);
    }
}

.textarea h2,
%echo,
.echo {
    @include responsive-text(320px, breakpoint(1400), 18px, 28px);
    line-height: 1.4;
}

.textarea h3,
%foxtrot,
.foxtrot {
    @include responsive-text(320px, breakpoint(1400), 16px, 24px);
    line-height: 1.4;
}

.textarea h4,
%golf,
.golf {
    font-size: inherit;
    line-height: 1.5;
}

.background-heading {
    position: fixed;
    z-index: -1;
    top: 50%;
    left: 50%;
    backface-visibility: hidden;
    transform: translate(-50%, -50%);
    width: 80%;
    margin: 0;

    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s $ease-out;

    .site-section.-active & {
        opacity: 1;
    }

    img {
        width: 100%;
        height: auto;
    }
}
/* stylelint-enable selector-no-type, selector-no-qualifying-type */
