.loader-container {
  background: color(white);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  pointer-events: none;
  transform: translateX(-100%);
  opacity: 0;
  transition: opacity 0.4s, transform 0s 0.4s;
  will-change: opacity, transform;

  .-page-loading & {
    opacity: 1;
    transform: none;
    transition: opacity 0.4s;
  }
}

.loader {
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -75px;
  margin-left: -150px;

  &::before {
    content: '';
    display: block;
    padding-top: 50%;
  }

  canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
