

/**
 * translateSquiggle
 * moves the background-image position
 *
 * used by:
 *      .site-section__squiggle-icon::after
 **/

@keyframes translateSquiggle {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 0;
    }
}

@keyframes fadeup {
    from {
        opacity: 0;
        transform: translateY(40px);
    }
    to {
        opacity: 1;
        transform: none;
    }
}

.fade-up {
    transform: translate3d(0, $fade-up-distance-mobile, 0);
    opacity: 0;
    transition: transform $fade-duration $ease-out, opacity $fade-duration;

    &.-in-view,
    .-in-view:not(.events):not(.events__month-container) & {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    @media (min-width: 600px) {
        transform: translate3d(0, $fade-up-distance, 0);
    }
}

.fade-up-short {
    transform: translate3d(0, 20px, 0);
}

%fade-up-2,
.fade-up-2 {
    transition-delay: 0.2s, 0.2s;
}
%fade-up-3,
.fade-up-3 {
    transition-delay: 0.4s, 0.4s;
}
%fade-up-4,
.fade-up-4 {
    transition-delay: 0.6s, 0.6s;
}
%fade-up-5,
.fade-up-5 {
    transition-delay: 0.8s, 0.8s;
}
%fade-up-6,
.fade-up-6 {
    transition-delay: 1s, 1s;
}
%fade-up-7,
.fade-up-7 {
    transition-delay: 1.2s, 1.2s;
}

.fade-up-image {
    transform: translateY($fade-up-distance-mobile);
    opacity: 0;
    transition: transform $fade-duration 1.7s $ease-out, opacity $fade-duration 1.7s;

    @media (min-width: 600px) {
        transform: translateY($fade-up-distance);
    }

    .fonts-loaded.heading-ready &.-visible {
        transform: none;
        opacity: 1;
    }
    .landing & {
        transition-delay: 2.6s, 2.6s;
    }
}

/* stylelint-disable declaration-no-important */
%fade-up-immediate,
.fade-up-immediate {
    opacity: 0;
    transform: translateY($fade-up-distance-mobile);

    @media (min-width: 600px) {
        transform: translateY($fade-up-distance);
    }

    .fonts-loaded.heading-ready & {
        animation: fadeup $fade-duration 1.7s $ease-out forwards;
    }

    .fonts-loaded.heading-ready.landing & {
        animation-delay: 2.6s;
    }

    .-ajaxed & {
        animation-delay: 0s !important;
    }
}
/* stylelint-enable declaration-no-important */

%shape-circle {
    display: block;
    background: color(primary-light);
    border-radius: 50%;
}

%shape-rect {
    display: block;
    background: color(primary);
}

/* stylelint-disable string-quotes */
%shape-curve {
    display: block;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='307.6' height='131.21' viewBox='0 0 307.6 131.21'%3E%3Cpath fill='%23ffffff' d='M307.6,78.06l-63.37,53.14C196.17,73.9,110.45,66.38,53.14,114.43L0,51.06a218.34,218.34,0,0,1,307.6,27Z'/%3E%3C/svg%3E") top left no-repeat;
    width: 308px;
    height: 132px;
}

%shape-curve-black {
    display: block;
    background: url('../img/svg/curve-black.svg') top left no-repeat;
    width: 308px;
    height: 132px;
}
/* stylelint-enable string-quotes */



@keyframes h1 {
    to {
        transform: none;
    }
}
