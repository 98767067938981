.block-image {
  @media (min-width: 600px) {
    display: flex;
  }
}

.block-image__block {
  position: relative;
  flex: 0 0 auto;
  margin: 0 -10px;

  > div {
    background: color(white);
    padding: 30px;
  }

  h3,
  p {
    width: 100%;
    max-width: 300px;
  }

  h3 {
    margin-bottom: 20px;
    @extend %fade-up-2;
  }
  p {
    @extend %fade-up-3;
  }

  @media (min-width: 600px) {
    width: calc(50% - 40px);
    margin: 0 20px;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 40px;
    }
  }
}

.block-image__block--primary,
.block-image__block--nimbus {
  color: color(white);
}

.block-image__block--primary > div {
  background: color(primary);
}

.block-image__block--nimbus > div {
  background: color(nimbus);
}

/* stylelint-disable declaration-no-important */
.block-image__image {
  flex: 0 0 auto;
  margin: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 600px) {
    width: calc(50% - 40px);
    margin: 0 20px;
    transition-delay: 0.6s, 0.6s !important; // .fade-up-4
  }

  > div {
    position: relative;

    .block-image--analyse & {
      width: ((468 / 665) * 100%) + 30%;
    }
    .block-image--organise & {
      width: ((313 / 665) * 100%) + 30%;
    }
    .block-image--strategise & {
      width: ((364 / 665) * 100%) + 30%;
    }
    .block-image--implement & {
      width: ((286 / 665) * 100%) + 30%;
    }
    .block-image--amplify & {
      width: ((393 / 665) * 100%) + 30%;
    }
    .block-image--refine & {
      width: ((300 / 665) * 100%) + 30%;
    }

    @media (min-width: 600px) {
      .block-image--analyse & {
        width: ((468 / 665) * 100%) + 10%;
      }
      .block-image--organise & {
        width: ((313 / 665) * 100%) + 10%;
      }
      .block-image--strategise & {
        width: ((364 / 665) * 100%) + 10%;
      }
      .block-image--implement & {
        width: ((286 / 665) * 100%) + 10%;
      }
      .block-image--amplify & {
        width: ((393 / 665) * 100%) + 10%;
      }
      .block-image--refine & {
        width: ((300 / 665) * 100%) + 10%;
      }
    }

    @media (min-width: 1400px) {
      .block-image--analyse & {
        width: (468 / 665) * 100%;
      }
      .block-image--organise & {
        width: (313 / 665) * 100%;
      }
      .block-image--strategise & {
        width: (364 / 665) * 100%;
      }
      .block-image--implement & {
        width: (286 / 665) * 100%;
      }
      .block-image--amplify & {
        width: (393 / 665) * 100%;
      }
      .block-image--refine & {
        width: (300 / 665) * 100%;
      }
    }

    &::before {
      content: '';
      display: block;

      .block-image--analyse & {
        padding-top: (278.92 / 466.53) * 100%;
      }
      .block-image--organise & {
        padding-top: (249.18 / 312.45) * 100%;
      }
      .block-image--strategise & {
        padding-top: (255.73 / 362.83) * 100%;
      }
      .block-image--implement & {
        padding-top: (264.66 / 284.65) * 100%;
      }
      .block-image--amplify & {
        padding-top: (230.3 / 392.29) * 100%;
      }
      .block-image--refine & {
        padding-top: (285.99 / 298.65) * 100%;
      }
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
/* stylelint-enable declaration-no-important */

.block-image:nth-child(odd) .block-image__block {
  order: -1;
}
