.split-modal {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-scroller-page;
  overflow: auto;
  overflow-x: hidden;
  color: color(nimbus);
  -webkit-overflow-scrolling: touch;

  backface-visibility: hidden;

  &:not(.split-modal--active) {
    transform: translateX(-100%);
    z-index: -1;

    /* stylelint-disable time-no-imperceptible */
    transition: transform 0s 2s, z-index 0s 2s;
    /* stylelint-enable time-no-imperceptible */
  }

  &::before,
  &::after {
    content: '';
    display: block;
    width: 50%;
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    transition: transform 0.4s 0.7s $ease-out;

    will-change: transform;
  }

  &::before {
    background: color(white);
    left: 0;
    transform: translateY(100%);
    z-index: 2;
  }

  &::after {
    right: 0;
    background-color: color(primary-dark);
    transform: translateY(-100%);
    z-index: 0;
  }

  /* stylelint-disable selector-no-type */
  a {
    color: inherit;
  }

  h2 a {
    padding: 0;
  }
  /* stylelint-enable selector-no-type */

  .input {
    opacity: 0;
    transition: opacity 0.4s 0.5s $ease-out;

    &::before {
      transform-origin: 0 50%;
      transform: scale(0);
      transition: transform 0.4s 0.6s $ease-out;
    }

    &:nth-child(2),
    &:nth-child(2)::before {
      transition-delay: 0.5s;
    }
    &:nth-child(3),
    &:nth-child(3)::before {
      transition-delay: 0.4s;
    }
    &:nth-child(4),
    &:nth-child(4)::before {
      transition-delay: 0.3s;
    }
    &:nth-child(5),
    &:nth-child(5)::before {
      transition-delay: 0.2s;
    }
  }

  .submit {
    text-align: right;
    opacity: 0;
    transform: translateY(30px);
    transition: transform 0.4s $ease-out, opacity 0.4s;
  }

  .big-squiggle {
    position: fixed;
    z-index: 2;
    bottom: 10%;
    left: 50%;
    margin-left: -100px;
    width: 228.4px;

    div {
      opacity: 0;
      transition: transform 0.8s $ease-out, opacity 0.4s;
    }

    @media (max-width: 1100px) {
      display: none;
    }
  }
}

/* stylelint-disable no-descending-specificity */
.split-modal--active {
  &::before,
  &::after {
    transform: none;
    transition: transform 0.4s $ease-out;
  }

  .site-container {
    &::before {
      opacity: 0.05;
      transform: rotate(180deg);
      transition-delay: 0.4s, 0.4s;
    }
  }

  .site-nav__link.-close {
    opacity: 1;
  }

  .input {
    opacity: 1;
    transition-delay: 0.2s;
    &::before {
      transform: none;
      transition-delay: 0.2s;
    }
    &:nth-child(2),
    &:nth-child(2)::before {
      transition-delay: 0.3s;
    }
    &:nth-child(3),
    &:nth-child(3)::before {
      transition-delay: 0.4s;
    }
    &:nth-child(4),
    &:nth-child(4)::before {
      transition-delay: 0.5s;
    }
    &:nth-child(5),
    &:nth-child(5)::before {
      transition-delay: 0.6s;
    }
  }

  .submit {
    opacity: 1;
    transform: none;
    transition-delay: 0.7s, 0.7s;
  }

  .big-squiggle {
    div {
      opacity: 1;
      transform: translateX(-33.3333333%);
      transition-delay: 0.1s, 0.1s;
    }
  }
}
.split-modal--relative {
  height: 100vh;
  position: relative;
}

.-split-modal-leaving .split-modal .big-squiggle div {
  transform: translateX(-66.6666666%);
}
/* stylelint-enable no-descending-specificity */

.split-modal-inner {
  display: flex;
  align-items: flex-start;
  flex: 0 0 auto;
  width: 100%;
  margin: auto;

  &::before {
    content: '';
    @extend %shape-rect;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 50%;
    opacity: 0;
    transform-origin: 0 0;
    transform: rotate(24deg) translateY(-50px);
    transition: transform 0.6s $ease-out, opacity 0.6s;

    width: 280px;
    height: 120px;

    will-change: transform, opacity;

    @media (min-width: breakpoint(1200)) {
      width: 650px;
      height: 150px;
    }

    .split-modal--active & {
      transform: rotate(24deg);
      opacity: 0.3;
      transition-delay: 0.4s, 0.4s;
    }
  }

  &::after {
    content: '';
    @extend %shape-circle;
    position: fixed;
    z-index: 1;
    top: 100%;
    left: 100%;
    transform: translate(-76%, -86%);
    opacity: 0;
    transition: transform 0.6s $ease-out, opacity 0.6s;

    width: 300px;
    height: 300px;

    will-change: transform, opacity;

    .split-modal--active & {
      transform: translate(-96%, -86%);
      opacity: 0.3;
      transition-delay: 0.4s, 0.4s;
    }

    @media (min-width: breakpoint(1200)) {
      width: 500px;
      height: 500px;
    }
  }
}

.split-modal-col {
  z-index: 3;
  flex: 0 0 auto;
  width: 50%;

  display: flex;

  &:first-child {
    opacity: 0;
    transition: opacity 0.5s 0.4s;

    @media (min-width: 1101px) {
      padding-left: 20px;
      padding-right: 100px;
    }

    @media (min-width: 1400px) {
      padding-right: 180px;
    }

    @media (min-width: 1800px) {
      padding-right: 260px;
    }

    .split-modal--active & {
      opacity: 1;
      transition-delay: 0.2s;
    }
  }

  &:last-child {
    @media (min-width: 1101px) {
      padding-left: 100px;
      padding-right: 20px;
    }

    @media (min-width: 1400px) {
      padding-left: 180px;
    }

    @media (min-width: 1800px) {
      padding-left: 260px;
    }
  }

  /* stylelint-disable selector-no-type */
  > div {
    width: 100%;
  }
  /* stylelint-enable selector-no-type */

  @media (max-width: 1100px) {
    width: calc(100% + 160px);
    margin-left: -80px;
    margin-right: -80px;
    padding-left: 100px;
    padding-right: 100px;
  }

  @media (max-width: 767px) {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.split-modal-col--left {
  padding-top: 100px;
  padding-bottom: 50px;

  @media (min-width: breakpoint(500)) {
    padding-bottom: 100px;
  }

  @media (min-width: breakpoint(800)) {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  @media (min-width: breakpoint(1200)) {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}
.split-modal-col--right {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 40px;
    display: block;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='307.6' height='131.21' viewBox='0 0 307.6 131.21'%3E%3Cpath fill='%23000000' d='M307.6,78.06l-63.37,53.14C196.17,73.9,110.45,66.38,53.14,114.43L0,51.06a218.34,218.34,0,0,1,307.6,27Z'/%3E%3C/svg%3E")
      top left no-repeat; /* stylelint-disable-line */
    width: 308px;
    height: 132px;
    opacity: 0;
    transform: translateX(-30%);

    transition: transform 0.6s $ease-out, opacity 0.6s;

    .split-modal--active & {
      opacity: 0.1;
      transform: none;
      transition-delay: 0.2s;
    }
  }
}

.split-modal-info {
  margin-left: -20px;
  margin-right: -20px;
  line-height: 1.8;
}

.split-modal-info__col {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: $spacing;

  .btn {
    padding: 29px;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
}

.split-modal-info__heading {
  margin-top: 1rem;
  margin-bottom: rem(20px);
  line-height: 1.2;
}

.split-modal-social {
  list-style: none;
  margin: 0;
}

@media (max-width: 1100px) {
  .split-modal,
  .split-modal-inner {
    display: block;
    background: color(primary-dark);
  }

  .split-modal {
    /* stylelint-disable declaration-no-important */
    transition: transform 0.4s $ease-out, opacity 0.4s !important;
    /* stylelint-enable declaration-no-important */

    will-change: transform, opacity;
  }
  .split-modal:not(.split-modal--active) {
    transform: translateY(100%);
    opacity: 0;
  }

  .split-modal-inner {
    &::before,
    &::after {
      /* stylelint-disable declaration-no-important */
      display: none !important; // hide shapes
      background: color(primary) !important;
      opacity: 0 !important;
      /* stylelint-enable declaration-no-important */

      position: absolute;
    }
    &::before {
      left: -10%;
      width: 90%;
    }
    &::after {
      transform: translate(-54%, -144%);

      .split-modal--active & {
        transform: translate(-74%, -144%);
      }
    }
  }
  .split-modal--active .split-modal-inner::before,
  .split-modal--active .split-modal-inner::after {
    /* stylelint-disable declaration-no-important */
    opacity: 0.1 !important;
    /* stylelint-enable declaration-no-important */
  }

  .split-modal::before,
  .split-modal::after {
    display: none;
    will-change: auto;
  }

  .split-modal-col:first-child {
    background: color(white);
  }

  .split-modal-col--right::before {
    display: none;
  }
}
