/*
.news-view {

}
*/

.news-view__baseline {
    border-bottom: 1px solid color(lilywhite);
    margin: 0 20px;
}

.news-share__items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    margin-top: $spacing * -1;
}

.news-share__item {
    width: 100%;
    flex: 0 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    margin-top: $spacing;

    @media (min-width: 600px) {
        width: 33.3333333%;
    }

    @media (min-width: 1400px) {
        width: 16.6666666%;
    }

    .btn {
        width: 170px;
        vertical-align: top;
        padding-right: 0;
        padding-left: 0;

        @media (min-width: 600px) {
            width: 100%;
        }
    }

    &.caption {
        width: 100%;
        text-align: center;

        @media (min-width: 1400px) {
            width: 16.6666666%;
            text-align: left;
        }
    }
}

/*
.news-related {

}
*/
