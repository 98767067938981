.service-view {
    background: none;
}

.service-columns {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin: 0 20px;
}

.service-column__title {
    margin-bottom: $spacing * 2;
}

.service-column__squiggle {
    @include centered;

    width: 79px;
    height: 8px;
}

/* stylelint-disable declaration-no-important */
.service-column {
    width: 100%;
    flex: 0 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    backface-visibility: hidden;

    @extend %fade-up-immediate;

    > div {
        margin-top: -40px;
    }

    &.-nimbus {
        background: color(nimbus);
        margin-bottom: 40px;

        .service-column__title {
            color: color(overcast);
        }
    }

    &.-primary {
        background: color(primary);
        margin-top: 40px;
        animation-delay: 2.4s !important;

        .landing & {
            animation-delay: 3.3s !important;
        }

        .service-column__title {
            color: rgba(color(white), 0.6);
        }

        .service-column__squiggle {

            /* stylelint-disable selector-no-type */
            path {
                fill: color(primary-dark);
            }
            /* stylelint-enable selector-no-type */
        }
    }

    @media (min-width: breakpoint(600)) {
        width: 50%;
    }
}
/* stylelint-enable declaration-no-important */

.service-column__list {
    @include clean-list;

    margin-bottom: $spacing * 2;
}

/*
.service-column__item {
}
*/
.service-column__link {
    line-height: 1.5;
    color: color(white);
    display: inline-block;
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateX(-101%);
        transition: transform 0.2s;
        background-color: color(white);
    }

    &:hover {
        &::after {
            transform: translateX(0);
        }
    }
}
