.careers-collage {
    display: flex;
    flex-wrap: wrap;
}

.careers-collage__col {
    width: calc(33.3333333% - 40px);
    flex: none;
    margin-left: 20px;
    margin-right: 20px;

    @media (max-width: 1199px) {
        width: calc(50% - 40px);
    }

    @media (max-width: 499px) {
        width: calc(100% - 40px);
    }

    > div {
        position: relative;
        margin-top: 40px;
    }

    img,
    > div > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        /* stylelint-disable declaration-no-important */
        height: 100% !important;
        /* stylelint-enable declaration-no-important */
    }
}


.careers-collage__col:nth-child(1) {
    @media (max-width: 499px) {
        margin-top: -40px;
    }

    > div:nth-child(1) {
        padding-top: percentage(652 / 796);
    }

    > div:nth-child(2) {
        padding-top: percentage(668 / 796);
    }
}

.careers-collage__col:nth-child(2) {
    @media (min-width: 500px) {
        margin-top: -40px;
    }

    > div:nth-child(1) {
        padding-top: percentage(956 / 682);
    }

    > div:nth-child(2) {
        padding-top: percentage(512 / 682);
    }
}

.careers-collage__col:nth-child(3) {
    @media (max-width: 1199px) {
        display: none;
    }

    > div:nth-child(1) {
        padding-top: percentage(512 / 682);
    }

    > div:nth-child(2) {
        padding-top: percentage(812 / 682);
    }
}
