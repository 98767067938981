.admin-table {
  width: 100%;

  /* stylelint-disable selector-no-type */
  th,
  td {
    padding: 10px;
    border: 1px solid color(nimbus);
  }

  th {
    background-color: color(primary);
    color: color(white);
  }

  td {
    background-color: color(pastel);
  }

  tr {
    &:nth-child(odd) {
      td {
        background-color: color(white);
      }
    }
  }
  /* stylelint-enable */
}

.admin-list,
.admin-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

.admin-heading {
  font-family: $font-tertiary;
  color: color(inkblot);
}
/* stylelint-disable declaration-no-important */
.expose-admin__edit-image-iframe {
  background: none !important;
}
/* stylelint-enable declaration-no-important */
