.scroller-page {
  width: 100%;
  position: relative;
  margin-left: 0;
  z-index: $z-scroller-page;
}

/* stylelint-disable declaration-block-no-duplicate-properties */
.scroller-page-nav {
  width: 40%;
  display: block;
  list-style: none;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    display: block;
    width: 1px;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: color(lilywhite);

    @media (min-width: 2120px) {
      left: 15%;
    }
  }

  @media (min-width: 950px) {
    width: calc(50% - 100px);
    left: 100px;
  }

  @media (min-width: 1200px) {
    width: calc(50% - 180px);
    left: 180px;
  }

  @media (min-width: 1800px) {
    width: calc(50% - 260px);
    left: 260px;
  }

  @media (max-width: 739px) {
    display: none;
  }
}

.scroller-page-nav__list {
  width: 220px;
  @include clean-list;
}

/* stylelint-disable declaration-no-important */
.scroller-page-nav__item {
  width: 100%;
  float: left;
  clear: both;
  position: relative;

  @for $i from 1 through 12 {
    &:nth-child(#{$i}) span {
      animation-delay: 0.2s + ($i * 0.1s) !important;

      .landing & {
        animation-delay: 1.8s + ($i * 0.1s) !important;
      }
    }
  }
}
/* stylelint-enable declaration-block-no-duplicate-properties */

.scroller-page-nav__link {
  color: color(overcast);
  display: inline-block;
  vertical-align: middle;
  font-size: rem(20px);
  line-height: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  transition: all 0.2s;
  overflow: hidden;

  .scroller-page-nav--visible & {
    overflow: visible;
  }

  &::before {
    @include middle(y, absolute);
    content: '';
    display: inline-block;
    width: 30px;
    height: 1px;
    left: -24px;
    background-color: rgba(color(nimbus), 0.3);
    transition: transform 0.2s;
    transform-origin: left;
    transform: scaleX(0);
  }

  span {
    display: inline-block;
    transition: transform 0.3s $ease-out;
    transform: translateX(-100%);

    .hero-loaded.fonts-loaded & {
      //transform: none;
      animation: scrollerNavSlideIn 0.3s $ease-out forwards;
    }

    .scroller-page-nav--visible & {
      animation: none !important;
      transform: none !important;
      transition-delay: 0s !important;
    }
  }

  .scroller-page-nav--visible &.-active {
    color: color(nimbus);

    &::before {
      transform: scaleX(1);
    }

    span {
      transform: translateX(16px) !important;
    }
  }
}
/* stylelint-enable declaration-no-important */

.scroller-page-nav__link--external {
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 100%;
    margin-left: 3px;
    width: 16px;
    height: 16px;
    background: url('../img/svg/icon-launch.svg') top left no-repeat;
    background-size: 16px 16px;

    opacity: 0;
    transition: transform 0.3s $ease-out, opacity 0.3s;
  }

  &.-active::after {
    transform: translateX(16px);
    opacity: 1;
  }
}

@keyframes scrollerNavSlideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: none;
  }
}

.scroller-page-scroller {
  //position: fixed;
  pointer-events: none;
  width: 50%;
  padding-top: 240px;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  transform-origin: right;
  transition: transform 0.2s ease-in-out;

  @media (max-width: 1799px) {
    padding-top: 190px;
  }
  @media (max-width: 1399px) {
    padding-top: 139px;
  }
  @media (max-width: 949px) {
    padding-top: 80px;
  }

  @media (max-width: 859px) {
    width: 60%;
  }
  @media (max-width: 739px) {
    position: static;
    width: 100%;
  }
}
.scroller-page-scroller--fixed {
  @media (min-width: 740px) {
    position: fixed;
  }
  pointer-events: auto;
}
.scroller-page-scroller--expand {
  transform: scale(1.1);
}

.scroller-page-scroller__list {
  @include clean-list;
  // transition: transform 1s cubic-bezier(0.860, 0.000, 0.070, 1.000)
  will-change: transform;
}

.scroller-page-scroller__item {
  position: relative;
  display: block;
  opacity: 0;
  transform: translateY($fade-up-distance-mobile);
  transition: transform 0.7s $ease-out, opacity 0.7s;

  @media (min-width: 600px) {
    transform: translateY($fade-up-distance);
  }

  @for $i from 1 through 12 {
    &:nth-child(#{$i}) {
      transition-delay: (($i - 1) * 0.2s);
    }
  }
}
.scroller-page-scroller__item--visible {
  opacity: 1;
  transform: none;
}

.scroller-page-scroller__link {
  display: block;
  position: relative;

  background-position: center;
  background-repeat: no-repeat;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: color(primary-dark);
    opacity: 0;
    transition: opacity 0.4s;
  }

  &:hover::after {
    opacity: 0.5;
  }

  .scroller-page-scroller__item:nth-child(even) & {
    background-color: color(primary-dark);
  }

  img {
    opacity: 0;
    transition: opacity 0.7s;
    will-change: opacity;

    .scroller-page-scroller__item.-loaded & {
      opacity: 1;
    }
  }
}

.scroller-page-scroller__details {
  position: absolute;
  bottom: 74px;
  left: 70px;
  opacity: 1;
  color: color(white);
  z-index: 2;
  transition: opacity 0.8s;
  pointer-events: none;

  @media (min-width: 740px) {
    .scroller-page-scroller__item:hover & {
      &::after {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -30%;
    left: 0;
    width: 24px;
    height: 24px;
    /* stylelint-disable string-quotes */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 24 24'%3E%3Cpath d='M22 12l-4-4v3H3v2h15v3z'/%3E%3C/svg%3E");
    /* stylelint-enable string-quotes */
    background-size: 24px 24px;

    transform: translateX(-20px);
    opacity: 0;
    transition: transform 0.2s $ease-out, opacity 0.3s;

    .scroller-page-scroller__item:hover & {
      transition-delay: 0.4s, 0.4s;
    }
  }

  @media (max-width: 739px) {
    pointer-events: auto;
    position: relative;
    bottom: auto;
    left: auto;
    padding: 25px 15px 15px;
    color: color(nimbus);
    display: block;
  }
}

.scroller-page-scroller__heading {
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.4s 0.3s, transform 0.4s 0.3s $ease-out;

  .scroller-page-scroller__item:hover & {
    opacity: 1;
    transform: none;
    transition-delay: 0s;
  }

  @media (max-width: 739px) {
    margin-bottom: 8px;
    opacity: 1;
    transform: none;
  }
}

.scroller-page-scroller__summary {
  display: block;
  margin-bottom: 8px;

  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.4s 0.1s, transform 0.4s 0.1s $ease-out;

  .scroller-page-scroller__item:hover & {
    opacity: 1;
    transform: none;
    transition-delay: 0.2s, 0.2s;
  }

  @media (max-width: 739px) {
    line-height: 1.45;
    opacity: 1;
    transform: none;
  }
}

.scroller-page-scroller__view-btn {
  color: color(primary);
  padding-left: 25px;
  position: relative;
  text-decoration: none;
  text-transform: lowercase;
  display: none;
  line-height: 1.45;

  &:hover {
    text-decoration: underline;
  }

  &::before {
    @include middle(y, absolute);
    content: '';
    display: block;
    width: 16px;
    height: 1px;
    background-color: color(primary);
    left: 0;
  }

  @media (max-width: 739px) {
    display: inline-block;
  }
}

.scroller-page .big-squiggle {
  position: fixed;
  z-index: 2;
  bottom: 20%;
  left: 50%;
  margin-left: -159px;
  width: 228.4px;

  /* stylelint-disable selector-no-qualifying-type */
  div {
    opacity: 0;
    transition: transform 0.8s $ease-out, opacity 0.4s;
    transform: translateX(-33.3333333%);

    &.skrollable {
      transition: none;
    }
  }
  /* stylelint-enable selector-no-qualifying-type */

  @media (max-width: 739px) {
    display: none;
  }
}

.scroller-page-scroller--fixed + .big-squiggle {
  div {
    opacity: 1;
    transform: none;
  }
}
