.site-footer {
  min-height: 100vh;
  text-align: center;
  position: relative;

  display: flex;
  align-items: center;

  &::before {
    content: '';
    @extend %shape-circle;
    position: absolute;
    z-index: 0;
    top: 90px;
    left: 80%;
    transform: translateY(-50%);
    opacity: 0;

    width: 350px;
    height: 350px;

    @media (min-width: 600px) {
      left: 70%;
    }

    @media (min-width: 800px) {
      width: 500px;
      height: 500px;
    }

    @media (min-width: 1200px) {
      top: 0;
    }
  }

  &::after {
    content: '';
    @extend %shape-rect;
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 100%;
    transform-origin: 100% 0;
    transform: rotate(-22deg);

    width: 650px;
    height: 150px;
  }

  .site-container {
    &::before {
      content: '';
      @extend %shape-curve;
      position: absolute;
      z-index: 0;
      top: 20%;
      right: 100%;
      transform: none;
      opacity: 0.05;

      @media (max-width: 767px) {
        top: 25%;
      }
    }
  }

  &::before,
  &::after,
  .site-container::before {
    /* stylelint-disable time-no-imperceptible */
    transition: transform 0.8s $ease-out, opacity 0.7s 0.1s;
    /* stylelint-enable */
  }

  &.-seen {
    &::before {
      // circle
      transform: translateY(-20%);
      opacity: 1;
      transition-delay: 0.3s;
    }
    &::after {
      // rect
      transform: rotate(-22deg) translateY(-170%);
      transition-delay: 0.2s;
    }
    .site-container::before {
      // curve
      transform: translateX(80%);

      @media (min-width: breakpoint(1200)) {
        transform: translateX(150%);
      }

      @media (max-width: 767px) {
        transform: translateX(60%);
      }
    }
  }
}

.site-footer.-seen:not(.-active) {
  &::before {
    opacity: 0;
    transition-delay: 0s, 0s;
  }
}

.footer-item {
  margin-bottom: 50px;

  position: relative;
  z-index: 1;

  &.-subscribe {
    margin-bottom: 50px;

    @media (min-width: 768px) {
      margin-bottom: 150px;
    }
  }
}

.footer-item__title {
  margin-bottom: 7px;
}

.footer-item__address {
  font-style: normal;
  line-height: 1.4;
}

.footer-item__email {
  @extend %textlink;
  display: inline-block;
  color: color(white);
  border-bottom-color: rgba(color(white), 0.3);

  &:hover {
    color: white;
    border-bottom-color: white;
  }
}

.footer-social {
  margin: 0 auto;
  padding: 70px 0;
  font-size: 50px;

  @media (max-width: 767px) {
    padding: 0;
    max-width: 240px;
  }
}

.footer-social__link {
  /* stylelint-disable selector-no-type */
  svg {
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
    }
  }
  /* stylelint-enable */
}

.footer-legal {
  color: rgba(color(white), 0.3);

  /* stylelint-disable selector-no-type */
  a {
    color: inherit;
    transition: color 0.2s;

    &:hover {
      color: rgba(color(white), 0.5);
    }
  }
  /* stylelint-enable */

  @media (max-width: 599px) {
    line-height: 2;
  }
}

.footer-legal__bull {
  &::before {
    content: '\0020\2022\0020';
  }
}

@media (max-width: breakpoint(500) - 1) {
  .footer-legal__item:first-child + .footer-legal__bull::before {
    content: '\A';
    white-space: pre;
  }
}

.site-footer .big-squiggle {
  width: 25%;
  max-width: 228.4px;
  position: absolute;
  left: 17%;
  bottom: 32%;

  div {
    opacity: 0;
    transition: transform 0.8s $ease-out, opacity 0.4s;
    transform: translateX(-33.3333333%);
  }

  @media (max-width: 767px) {
    left: -3%;
    bottom: 29%;
  }
  @media (max-width: 599px) {
    display: none;
  }
}
.site-footer.-in-view .big-squiggle div {
  opacity: 1;
  transform: none;
}

.footer-serverless {
  a {
    display: block;
    position: relative;
    width: 150px;
    opacity: 0.3;
    margin: 0 auto;
    transition: opacity 0.3s;

    &::before {
      content: '';
      display: block;
      padding-top: (180.5 / 616.9) * 100%;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
