.table:not(.awards__table),
.textarea table {
    display: block;
    width: calc(100% + 40px);
    color: color(primary);
    line-height: 1.3;
    margin-left: -20px;
    margin-right: -20px;

    thead,
    tbody {
        display: block;
    }

    tr {
        display: flex;
    }

    th,
    td {
        text-align: left;
        flex: 1 1 25%;
        margin: 0 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    th {
        @extend %caption;
        font-weight: 400;
        padding-bottom: 30px;
    }

    td {
        padding: 20px 0;
    }
}

.awards {
    position: relative;
}

.awards__table {
    width: 100%;
    table-layout: fixed;
    color: color(primary);
    line-height: 1.3;

    th {
        @extend %caption;
        font-weight: 400;
        width: 25%;

        &:first-child {
            @media (max-width: 999px) {
                width: 120px;
            }
            @media (max-width: 599px) {
                width: 52px;
            }
        }
    }

    th,
    td {
        text-align: left;
        vertical-align: top;
        padding: 0 20px;

        span {
            display: block;
            padding: 20px 0;
        }
    }

    th span {
        padding-top: 0;
    }

    td span {
        border-top: 1px solid rgba(0, 0, 0, 0.05);
    }

    th:nth-child(3),
    .awards__org {
        @media (max-width: 599px) {
            display: none;
        }
    }

    .awards__type {
        span::before {
            content: attr(data-org) ': ';
        }
    }

    tbody tr:last-child {
        td span {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }
    }
}

.awards__count {
    position: absolute;
    z-index: 0;
    font-size: 800px;
    color: rgba(#c5aeed, 0.1);
    left: 0;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
}
