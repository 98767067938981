/**
 * Helper styles
 * e.g. .centered { margin-right: auto; margin-left: auto; }
 */

/* stylelint-disable selector-no-type */
.hide {
    display: none;
}


.cf,
%cf {
    @include cf;
}

.img--responsive {
    max-width: 100%;
    height: auto;

    > img {
        max-width: 100%;
        height: auto;
    }
}

.img--max-full-centered {
    text-align: center;

    > img,
    > noscript > img {
        display: inline-block;
        max-width: 100%;
        height: auto;
    }
}

.img-ratio-1x1,
.img-ratio-1920x1280,
.img-ratio-1920x900,
.img-ratio-1370x900,
.img-ratio-1370x720,
.img-ratio-680x340,
.img-ratio-430x665,
.img-ratio-680x800,
.img-ratio-1026x684 {
    position: relative;
    background: color(primary);

    &::before {
        content: '';
        display: block;
    }
    > div:first-child,
    > img:first-child,
    > noscript img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.img-ratio-1x1 {
    &::before {
        padding-top: 100%;
    }
}
.img-ratio-1920x1280 {
    &::before {
        padding-top: (1280 / 1920) * 100%;
    }
}
.img-ratio-1920x900 {
    &::before {
        padding-top: (900 / 1920) * 100%;
    }
}
.img-ratio-1370x900 {
    &::before {
        padding-top: (900 / 1370) * 100%;
    }
}
.img-ratio-1370x720 {
    &::before {
        padding-top: (720 / 1370) * 100%;
    }
}
.img-ratio-680x340 {
    &::before {
        padding-top: (340 / 680) * 100%;
    }
}
.img-ratio-430x665 {
    &::before {
        padding-top: (665 / 430) * 100%;
    }
}
.img-ratio-680x800 {
    &::before {
        padding-top: (800 / 680) * 100%;
    }
}
.img-ratio-1026x684 {
    &::before {
        padding-top: (684 / 1026) * 100%;
    }
}

.video-ratio-1500x840,
.video-ratio-1204x834,
.video-ratio-1204x838 {
    position: relative;

    &::before {
        content: '';
        display: block;
    }

    > video,
    > img,
    > noscript img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.video-ratio-1500x840 {
    &::before {
        padding-top: (840 / 1500) * 100%;
    }
}
.video-ratio-1204x834 {
    &::before {
        padding-top: (834 / 1204) * 100%;
    }
}
.video-ratio-1204x838 {
    &::before {
        padding-top: (838 / 1204) * 100%;
    }
}

.sr-only,
%sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

/**
 * Fonts
 */
.font-secondary {
    font-family: $font-secondary;
}
.font-tertiary {
    font-family: $font-tertiary;
}

/**
 * Text alignment
 */
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}

/**
 * Text colors
 */
.color-primary {
    color: color(primary);
}
.color-white {
    color: color(white);
}
.color-overcast {
    color: color(overcast);
}

/**
 * Background colors
 */
.bg-light {
    /* stylelint-disable declaration-no-important */
    background-color: color(white) !important;
    /* stylelint-enable declaration-no-important */
}

.bg-dark {
    /* stylelint-disable declaration-no-important */
    background-color: color(primary-dark) !important;
    /* stylelint-enable declaration-no-important */
}

.bg-purple {
    /* stylelint-disable declaration-no-important */
    background-color: color(primary) !important;
    /* stylelint-enable declaration-no-important */
    color: color(white);
}

.bg-split {
    /* stylelint-disable declaration-no-important */
    background: linear-gradient(to right, #383247 50%, color(primary-dark) 50%) !important;
    /* stylelint-enable declaration-no-important */
}

.bg-transparent {
    /* stylelint-disable declaration-no-important */
    background-color: transparent !important;
    /* stylelint-enable declaration-no-important */
}

/**
 * Links
 */
%textlink {
    border-bottom: 1px solid rgba(color(primary), 0.3);
    padding-bottom: 5px;
    transition: color 0.2s, border-bottom-color 0.2s;

    &:hover {
        color: color(primary);
        border-bottom-color: color(primary);
    }
}

.textlink {
    @extend %textlink;
}

/**
 * Squiggles
 */
.big-squiggle {
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        display: block;
        padding-top: (29.82 / 228.4) * 100%;
    }

    div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 300%;
        background: url('../img/svg/squiggle-repeating.svg') top left repeat-x;
        background-size: 33.3333333% 100%;
    }
}

/* stylelint-disable declaration-no-important */
.m-0 {
    margin: 0 !important;
}
.m-b-0 {
    margin-bottom: 0 !important;
}
.m-b-40 {
    margin-bottom: 40px !important;
}
.m-l-20 {
    margin-left: 20px !important;
}
.p-t-0 {
    padding-top: 0 !important;
}
.p-b-0 {
    padding-bottom: 0 !important;
}
/* stylelint-enable declaration-no-important */
/* stylelint-enable selector-no-type */


.br {
    &::before {
        content: '\A';
        white-space: pre;
    }
}

/* stylelint-disable */
%trim,
.trim {
    > *:last-child {
        margin-bottom: 0 !important;
    }
    > *:first-child {
        margin-top: 0 !important;
    }
}

.lh-1 {
    line-height: 1 !important;
}
/* stylelint-enable */
