.jspdf-demo {
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-right: 20px;
}
.jspdf-demo__col {
    flex: 0 0 auto;
    width: 100%;

    &.-code {
        background-color: #2c283c;
    }
    &.-preview {
        background-color: #e2dfeb;
    }

    @media (min-width: 750px) {
        width: 50%;
    }
}
.jspdf-demo__header {
    width: 100%;
    height: 70px;
    padding: 0 22px 0 30px;
    display: flex;
    align-items: center;

    @media (min-width: 750px) {
        height: 90px;
        padding: 0 22px 0 40px;
    }

    &.-purple {
        background-color: color(primary);
        padding-right: 30px;

        .caption {
            color: rgba(color(white), 0.5);
            line-height: 1.2;
            flex: 0 1 30%;
        }

        @media (min-width: 750px) {
            padding-right: 40px;
        }
    }
    &.-grey {
        background-color: #c6c1d3;

        .caption {
            color: color(white);
            line-height: 1.2;
        }
    }
    &.-align-left {
        .caption {
            display: inline-block;
            margin-right: 55px;
        }
    }
    &.-space-between {
        justify-content: space-between;
    }

    .filter,
    .dropdown-container {
        height: 45px;
    }
    .filter {
        margin-top: 0;
    }
    .dropdown {
        flex: 1 1 auto;
    }
    .dropdown-container {
        top: -3px;
    }
    .dropdown__container {
        width: 100%;
        max-width: 220px;
    }
}
.jspdf-demo__download {
    width: auto;

    .caption {
        vertical-align: middle;
    }
}
.jspdf-demo__download-link {
    width: 32px;
    height: 32px;
    display: inline-block;
    vertical-align: middle;
    background-color: #a6a2b5;
    border-radius: 50%;
    margin-left: 20px;
    position: relative;
    transition: background-color 0.15s;

    &:hover {
        background-color: lighten(#a6a2b5, 3%);
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -9px;
        margin-left: -9px;
        fill: color(white);
    }

    @media (min-width: 750px) {
        width: 55px;
        height: 55px;

        svg {
            width: 16px;
            height: 16px;
            margin-top: -8px;
            margin-left: -8px;
        }
    }
}
.jspdf-demo__content {

    &.-preview {
        padding: 40px;
    }
}

.jspdf__logo,
.jspdf__intro,
.jspdf__button {
    transform: translateY($fade-up-distance-mobile);
    opacity: 0;

    @media (min-width: 600px) {
        transform: translateY($fade-up-distance);
    }

    .fonts-loaded.hero-loaded.heading-ready & {
        animation: fadeup $fade-duration $ease-out forwards;
    }
}

/* stylelint-disable declaration-no-important */
.jspdf__logo {
    width: 243px;
    height: 70px;
    margin: 0 auto 40px;

    img {
        width: 100%;
        height: auto;
        // vertical-align: top;
        display: block;
        margin: 0 auto;
    }

    .fonts-loaded.hero-loaded.heading-ready & {
        animation-delay: 0.4s;
    }
    .landing & {
        animation-delay: 1.3s !important;
    }
}
.jspdf__intro {
    max-width: 500px;

    .fonts-loaded.hero-loaded.heading-ready & {
        animation-delay: 0.6s;
    }
    .landing & {
        animation-delay: 1.5s !important;
    }
}
.jspdf__button {
    .fonts-loaded.hero-loaded.heading-ready & {
        animation-delay: 0.8s;
    }
    .landing & {
        animation-delay: 1.7s !important;
    }
}
/* stylelint-enable declaration-no-important */
