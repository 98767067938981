.work-item {
  position: relative;

  h3,
  p {
    margin: 0;
    width: 100%;
  }

  h3 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;

    @media (min-width: 600px) {
      margin-bottom: 20%;
    }
  }

  .flip-btn {
    color: inherit;

    @media (min-width: 600px) {
      margin-top: 30px;
    }
  }

  @media (min-width: 600px) {
    &:nth-of-type(2n) {
      .work-item__img {
        margin-left: 20px;
        margin-right: auto;
      }

      .work-item__info {
        left: auto;
        right: 0;
        margin-right: 20px;
      }
    }
  }
}

.work-item__img {
  display: block;
  background: none;
  overflow: hidden;
  margin-left: -10px;
  margin-right: -10px;
  transition: transform 0.4s $ease-out;

  @media (min-width: 600px) {
    margin-left: auto;
    margin-right: 20px;
    width: calc(83.3333333% - 40px);
  }

  img {
    transform: scale(1.05);
    transition: transform 0.4s $ease-out;
  }

  &:hover {
    transform: scale(1.05);

    img {
      transform: scale(1);
    }
  }
}

.work-item__info {
  color: color(white);
  left: 0;
  bottom: 4%;
  margin-left: -10px;
  margin-right: -10px;
  padding: 15px 20px;
  background: color(primary);

  @media (min-width: 600px) {
    position: absolute;
    width: calc(50% - 40px);
    margin-left: 20px;
    margin-right: 0;
    padding: 0;
    background: none;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    > div {
      background: color(primary);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0 18%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }

  @media (min-width: 1300px) {
    width: calc(33.3333333% - 40px);
    bottom: 10%;
  }
}

.work-item__squiggle {
  width: 16.6666666%;
  margin: 100px 0 100px calc(33.3333333% + 20px);
  opacity: 0.3;

  &:nth-of-type(even) {
    margin-left: calc(50% + 20px);
  }

  @media (max-width: 599px) {
    display: none;
  }
}
