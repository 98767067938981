.social-icons {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 40px;

  li {
    width: 1.08em; // 43.2px
    height: 1.08em; // 43.2px
    overflow: hidden;

    opacity: 0;
    transition: opacity 0.6s;

    &:nth-child(1) {
      transition-delay: 0.3s;

      .body--home & {
        transition-delay: 0.3s + 1s;
      }
    }
    &:nth-child(2) {
      transition-delay: 0.2s;

      .body--home & {
        transition-delay: 0.2s + 1s;
      }
    }
    &:nth-child(3) {
      transition-delay: 0.1s;

      .body--home & {
        transition-delay: 0.1s + 1s;
      }
    }
    &:nth-child(4) {
      .body--home & {
        transition-delay: 1s;
      }
    }

    .fonts-loaded.hero-loaded &,
    body:not(.landing) & {
      opacity: 1;
    }
  }
}

.social-icons--h {
  max-width: 7.5em; // 300px
}

.social-icons--v {
  flex-direction: column;

  li {
    margin-top: 0.5em;
  }
}

.social-icon {
  position: relative;
  display: block;
  width: 1em; // 40px
  height: 1em; // 40px
  text-decoration: none;
  margin: 0.04em 0 0 0.04em; // 1.6px 0 0 1.6px
}

.social-icon__icon {
  height: 0.625em; // 25px
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0.175em;
  fill: #fff;
}

.social-icon__ring {
  width: 1em; // 40px
  height: 1em; // 40px
  transform: rotate(-90deg);
  transition: transform 0.2s ease-out, opacity 0.5s;
  opacity: 0.5;

  circle {
    fill: none;
    stroke: #fff;
    stroke-width: 7;
    stroke-dasharray: 565.486677646;
    stroke-dashoffset: 565.486677646;
  }
}

.social-icon__ring--big {
  transform: rotate(-90deg) scale(1.1);
  opacity: 1;
}
