/*doc
---
title: Buttons
name: button
category: Buttons
---

Button styles can be applied to any element. Typically you'll want to use either a `<button>` or an `<a>` element.

Buttons should have a base .btn class, and a colour variant, such as -primary or -nimbus

```html_example
<button class="btn -nimbus">Button Text</button>
<a class="btn -primary" href="#">Button Text</a>
```

*/
.btn {
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    position: relative;

    font-family: $font-secondary;
    font-size: rem(16px);
    line-height: 1.4;
    text-align: center;

    padding: 29px 54px;

    border: 0;
    border-radius: 0;
    outline: 0;

    background-color: transparent;
    background-size: 100% 200%;
    background-repeat: no-repeat;
    background-position: 0 0;

    transition: color $trans-speed, background-position $trans-speed, box-shadow $trans-speed;


    &:hover {
        background-position: 0 100%;
    }


    &.-primary,
    .body--white &,
    .body--cloud &,
    .body--shapes &,
    .-split-modal-active & {
        color: color(primary);
        background-image: linear-gradient(to bottom, transparent 0%, transparent 50%, color(primary) 50%, color(primary) 100%);
        box-shadow: inset 0 0 0 2px rgba(color(primary), 0.3);

        &:hover,
        &:focus {
            box-shadow: inset 0 0 0 2px color(primary);
        }

        &:hover {
            color: color(white);
        }
    }
    &,
    .body--white & {
        &.-primary-reverse {
            color: color(white);
            background-position: 0 100%;

            &:hover,
            &:focus {
                color: color(primary);
                background-position: 0 200%;
            }
        }
    }

    &.-nimbus {
        color: color(nimbus);
        background-image: linear-gradient(to bottom, transparent 0%, transparent 50%, color(nimbus) 50%, color(nimbus) 100%);
        box-shadow: inset 0 0 0 2px rgba(color(nimbus), 0.3);

        &:hover,
        &:focus {
            box-shadow: inset 0 0 0 2px color(nimbus);
        }

        &:hover {
            color: color(white);
        }
    }

    &.-white,
    .body--primary &,
    .body--dark & {
        color: color(white);
        background-image: linear-gradient(to bottom, transparent 0%, transparent 50%, color(white) 50%, color(white) 100%);
        box-shadow: inset 0 0 0 2px rgba(color(white), 0.3);

        &:hover,
        &:focus {
            box-shadow: inset 0 0 0 2px color(white);
        }

        &:hover {
            color: color(primary);
        }
    }

    .body--dark & {
        &:hover {
            color: color(primary-dark);
        }
    }

    &.-fixed-170 {
        width: 170px;
    }

    &[disabled] {
        opacity: 0.3;
        pointer-events: none;
    }
}

.skip-to-content:focus {
    z-index: $z-header;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
    top: 30px;
    left: 30px;
    display: inline-block;
    color: color(white);
    padding: 0 10px;

    .body--white &,
    .body--shapes & {
        color: color(nimbus);
    }
}


.text-btn {
    color: #a0a0a0;
    text-transform: lowercase;
    line-height: 1;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    font-size: (16 / 18) * 100%;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: 2px;
        height: 1px;
        background: #a0a0a0;
        transform-origin: left;
        transform: scaleX(0.3) translateX(-140%);
        transition: transform 0.3s $ease-out;
    }

    &:hover::before {
        transform: none;
    }

    span {
        display: inline-block;
    }

    &--purple {
        @extend .text-btn;
        color: color(primary);
        transition: color 0.3s ease-in-out;

        span {
            padding-bottom: 10px;
            border-bottom: 1px solid color(primary-light);
            transition: border-bottom 0.3s ease-in-out;
        }

        &::before {
            background: color(primary);
            margin-top: -4px;
        }

        &:hover {
            color: color(primary-light);

            span {
                border-bottom: 1px solid transparent;
            }
        }
    }
}

.flip-btn {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: color(primary);
    @include on-dark {
        color: color(white);
    }
    line-height: 1;
    padding-left: 26px;
    text-transform: lowercase;
    padding-bottom: 4px;
    font-size: 13px;

    @media (min-width: 800px) {
        font-size: 16px;
        padding-left: 36px;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        background: currentColor;
        left: 0;
        width: 20px;
        top: 50%;
        margin-top: -1px;
        transition: transform 0.3s $ease-out;
        transform-origin: left;

        @media (min-width: 800px) {
            width: 30px;
        }
    }

    span {
        display: inline-block;
        transition: transform 0.3s $ease-out;
    }

    &:hover {
        span {
            transform: translateX(12px);
            @media (min-width: 800px) {
                transform: translateX(18px);
            }
        }
        &::before {
            transform: scaleX(1.333);
        }
    }
}

.flip-btn--white {
    color: color(white);
}

.flip-btn--grey {
    color: #a0a0a0;
}

.flip-btn--auto {
    @include on-dark {
        color: color(white);
    }
    @include on-light {
        color: color(primary);
    }
}

