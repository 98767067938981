.shapes {
  min-height: 100vh;
  position: fixed;
  z-index: -4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  transition: opacity 0.6s;

  &.-invisible {
    opacity: 0;
  }

  .-page-loading & {
    transition: opacity 0.1s;
  }

  &.-hidden {
    display: none;
  }

  .body--events_index & {
    opacity: 0;
  }

  .body--martinique:not(.body--events_index) & {
    opacity: 1;
    z-index: -1;
  }
}

.shapes--events {
  z-index: -1;
  opacity: 0;

  .body--events_index:not(.body--white):not(.body--dark) & {
    opacity: 1;
  }

  svg {
    height: 100vh;
  }
}

.shapes-canvas {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.shapes__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(1.05);
  background-size: cover;
  background-position: center center;

  opacity: 0;
  transition: opacity 1s (1.2s + 2s);

  .hero-loaded.fonts-loaded & {
    opacity: 1;
  }

  .body--events_index & {
    transition: opacity 1s;
  }
}

@keyframes homeheading {
  from {
    transform: translateY(100%);
    opacity: 1;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

.shapes__title {
  color: color(white);
  position: relative;
  margin: 0 50px;
  overflow: hidden;
  visibility: hidden;
  opacity: 0.5;
  letter-spacing: -0.04em;
  line-height: 1;

  @include responsive-text(320px, breakpoint(1400), 60px, 170px);

  div {
    overflow: hidden;
  }

  span {
    opacity: 0;
  }

  // force it on to two lines
  max-width: 88%;

  @media (min-width: 800px) {
    max-width: 65%;
  }
  @media (min-width: 1600px) {
    max-width: 52%;
  }
  @media (min-width: 2000px) {
    max-width: 42%;
  }

  .fonts-loaded.hero-loaded.heading-ready & {
    visibility: visible;
    span {
      display: inline-block;
      vertical-align: top;
      animation: homeheading 0.7s $ease-out forwards;
    }
  }
}

.shapes__subtitle {
  font-size: 16px;
  line-height: 1.5;
  color: color(white);
  margin: 0;
  order: 1;
  max-width: 100%;
  margin-top: 50px;
  padding: 0 40px;
  overflow: hidden;

  @media (min-width: 500px) {
    max-width: 80%;
  }

  @media (min-width: 700px) {
    max-width: 500px;
    font-size: 18px;
  }

  div {
    overflow: hidden;
  }

  span {
    opacity: 0;
  }

  .fonts-loaded.hero-loaded.heading-ready & {
    visibility: visible;
    span {
      display: inline-block;
      vertical-align: top;
      animation: homeheading 0.7s 0.7s $ease-out forwards;
    }
  }
}

.shapes__bg-switch {
  position: absolute;
  top: 50%;
  left: 0;
}
