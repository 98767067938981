/* stylelint-disable */
*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font: #{($base-font-size/16px) * 1em}/#{$line-height-ratio} $base-font-family;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
}

// Reset most elements' margin and padding to zero
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
dl,
dd,
ol,
ul,
form,
fieldset,
legend,
figure,
table,
th,
td,
caption,
hr {
  margin: 0;
  padding: 0;
}

// Reinstate a sensible, standardised amount of margin on certain elements
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
blockquote,
p,
address,
table,
fieldset,
figure,
pre {
  margin-bottom: $base-spacing-unit;
  margin-bottom: ($base-spacing-unit / $base-font-size) * 1rem;
}
// Create a standardised gap for bullets etc. on lists
ul,
ol,
dd {
  margin-left: 2 * $base-spacing-unit;
  margin-left: (2 * $base-spacing-unit / $base-font-size) * 1rem;
}

img,
svg {
  vertical-align: top;
}
/* stylelint-enable */
