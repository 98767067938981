/* stylelint-disable selector-no-universal, no-descending-specificity */
.two-col {
    @media (min-width: 800px) {
        display: flex;
        justify-content: space-between;
    }

    h2 {
        @extend %charlie;
    }
}

.two-col__col {
    flex: 0 0 50%;
    padding-left: 20px;
    padding-right: 20px;

    > * {
        margin-top: 0;
        margin-bottom: $spacing;
    }

    &:last-child > *:last-child {
        margin-bottom: 0;
    }

    @media (min-width: 800px) {
        > *:last-child {
            margin-bottom: 0;
        }
    }
}

.two-col__col--thin {
    flex-basis: 33.3333333%;

    @media (max-width: 799px) {
        width: 50%;
    }

    @media (max-width: 599px) {
        width: 66.6666666%;
    }
}


/**
 * Three column
 */
.three-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -50px;
    margin-right: -50px;
}

.three-col__col {
    flex: 0 0 33.3333333%;
    padding: 0 50px;

    &:nth-child(n+4) {
        margin-top: 100px;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}
/* stylelint-enable selector-no-universal, no-descending-specificity */
