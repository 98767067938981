.site-section {
    position: relative;

    /* stylelint-disable selector-no-universal */
    // > * {
    //     transition: opacity 0.4s;
    // }

    // &.-seen:not(.-active) > *:not(.line) {
    //     opacity: 0;
    // }
    /* stylelint-enable selector-no-universal */
}

/* stylelint-disable declaration-no-important */
.hero.-active:not(.-home) + .site-section > * {
    opacity: 1 !important;
}
/* stylelint-enable declaration-no-important */

.line {
    position: absolute;
    z-index: 1;
    width: 1px;
    height: 60px;
    background: #fff;
    top: -30px;
    left: 50%;
    will-change: transform, opacity;
    opacity: 1;
    transition: opacity 0.2s;

    .grid & {
        top: -10px;
    }

    &.-hide {
        opacity: 0;
    }

    @media (min-width: 568px) {
        height: 100px;
        top: -50px;

        .grid & {
            top: -30px;
        }
    }
    @media (min-width: 768px) {
        height: 160px;
        top: -80px;

        .grid & {
            top: -60px;
        }
    }
    @media (min-width: breakpoint(1200)) {
        height: 240px;
        top: -120px;

        .grid & {
            top: -100px;
        }
    }

    .body--white &,
    .body--cloud &,
    .body--dark &,
    .body--home &,
    .body--shapes &,
    .body--martinique & {
        background: color(primary);
    }
}

.line--first:not(.skrollable) {
    transform-origin: top;
    opacity: 0;

    .hero-loaded.fonts-loaded & {
        animation: lineIntro 0.7s 1.7s $ease-out forwards;
    }

    .hero-loaded.fonts-loaded.landing & {
        animation-delay: 2.6s;
    }
}

@keyframes lineIntro {
    from {
        transform: scaleY(0);
        opacity: 0;
    }
    to {
        transform: scaleY(1);
        opacity: 1;
    }
}

.site-section__squiggle-icon {
    width: 79px;
    height: 8px;
    overflow: hidden;

    // @media (max-width: 768px) {
    //     transform-origin: 50% 100%;
    //     transform: scale(0.8);
    // }

    &::after {
        @include background-svg('squiggle');

        content: '';
        display: block;
        width: 200%;
        height: 8px;

        background-repeat: repeat;
        background-size: 50% 100%;

        transform: translateX(-50%);
        opacity: 0;
        //transition: all 0.4s 1.4s;

        .hero-loaded.fonts-loaded & {
            animation: squiggleIntro 0.6s 1.1s $ease-out forwards;
        }

        .hero-loaded.fonts-loaded.landing & {
            animation-delay: 2s;
        }
    }

    &.-dark {
        &::after {
            @include background-svg('squiggle-dark');
            background-repeat: repeat;
        }
    }
}

@keyframes squiggleIntro {
    from {
        transform: translateX(-50%);
        opacity: 0;
    }
    to {
        opacity: 1;
        transform: none;
    }
}

.site-section__squiggle {
    padding: 20px;
    width: 119px;
    height: 48px;
    position: absolute;
    z-index: -1;
    bottom: 30px;
    left: calc(50% - 60px);

    &.-inline {
        position: relative;
        bottom: auto;
        left: auto;
        margin-top: 20px;
        margin-right: auto;
        margin-left: auto;
    }

    @media (min-width: 568px) {
        bottom: 50px;
    }

    @media (min-width: 768px) {
        bottom: 80px;
        padding: 40px;
        width: 159px;
        height: 88px;
        left: calc(50% - 80px);
    }

    @media (min-width: breakpoint(1200)) {
        bottom: 120px;
    }
}
