// scss-lint:disable all
/*-------------[MIXINS]-------------*/
// kinda link grunticon but but uses the svgmin gulp stuff
@mixin background-svg($file) {
  background-image: url('../img/svg/#{$file}.svg');
  background-repeat: no-repeat;
}

/*
 * centered
 * center a block element
 */
@mixin centered {
  margin-right: auto;
  margin-left: auto;
}

/*
 * cf
 * clearfix, for elements with floated children
 */
@mixin cf {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

/*
 * clean-list
 * make lists look like they aren't lists
 */
@mixin clean-list {
  margin-bottom: 0;
  margin-left: 0;

  /* stylelint-disable selector-no-type */
  > li {
    list-style: none;
  }
  /* stylelint-enable selector-no-type */
}

/*
 * Font Face

@include font-face('source-sans-pro', '/fonts/source-sans-pro/source-sans-pro-regular');
@include font-face('source-sans-pro', '/fonts/source-sans-pro/source-sans-pro-bold', bold);
@include font-face('source-sans-pro', '/fonts/source-sans-pro/source-sans-pro-italic', normal, italic);
 */
/* stylelint-disable indentation, value-list-comma-space-after */
@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    src: url('#{$file-path}.eot');
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
      url('#{$file-path}.woff') format('woff'),
      url('#{$file-path}.ttf') format('truetype'),
      url('#{$file-path}.svg##{$font-family}') format('svg');
  }
}
/* stylelint-enable indentation, value-list-comma-space-after */

/*
 * Fonts & related attrs

 @include font($helvetica, 20px, #b4da55, 400, 1.25);
 */

/* stylelint-disable block-no-single-line, block-closing-brace-newline-before */
@mixin font(
  $family: false,
  $size: false,
  $color: false,
  $weight: false,
  $lh: false
) {
  @if $family {
    font-family: $family;
  }
  @if $size {
    font-size: rem($size);
  }
  @if $color {
    color: $color;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
}
/* stylelint-enable block-no-single-line, block-closing-brace-newline-before */

/*
 * converts the Photoshop kerning value to an em value
 */
@mixin kerning($va) {
  letter-spacing: $va / 1000 + em;
}

/*
 * middle
 * vertically or horizontally align content
 */
@mixin middle($axis: '', $position: relative) {
  position: $position;

  /* stylelint-disable block-closing-brace-newline-after */
  @if $axis == y {
    top: 50%;
    transform: translateY(-50%);
  } @else if $axis == x {
    left: 50%;
    transform: translateX(-50%);
  } @else {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  /* stylelint-enable block-closing-brace-newline-after */
}

/*
 * Apply CSS to input placeholders

input {
    width: 300px;

    @include placeholder {
        color: red;
    }
}
 */
/* stylelint-disable */
@mixin placeholder {
  $placeholders: ':-webkit-input' ':-moz' '-moz' '-ms-input';
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}
/* stylelint-enable */

/*
 * Responsive Text
 * For when you want your text to scale with the viewport width
 * all arguments take a pixel value
 * $min-width: the minimum viewport width you want your text to start scaling
 * $max-width: the maximum viewport width you want your text to stop scaling
 * $min-size: the minimum font-size to start scaling with
 * $max-size: the maximum font-size to finish scaling with
 *
 * In the exmaple below, the font-size will be 15px at 400px viewport width
 * and it will scale up to 30px at 800px viewport width

.body-text {
    @include responsive-text(400px, 800px, 15px, 30px);
}
 */
@mixin responsive-text($min-width, $max-width, $min-size, $max-size) {
  $min-width-value: ($max-size - $min-size) / ($max-width - $min-width);
  $vw-value: (($max-size - $min-size) / ($max-width - $min-width) * 100) + vw;

  /* file: global */
  /* stylelint-disable declaration-block-no-duplicate-properties */
  font-size: $max-size - ($min-size / 2); // fallback
  font-size: calc(
    (#{$min-size} - #{$min-width} * #{$min-width-value}) + #{$vw-value}
  );
  /* stylelint-enable declaration-block-no-duplicate-properties */

  @media screen and (max-width: $min-width) {
    /* file: global */
    font-size: $min-size;
  }

  @media screen and (min-width: $max-width) {
    /* file: global */
    font-size: $max-size;
  }
}

/*
 * makes a CSS triangle

@include triangle(12px, gray, down);
@include triangle(12px 6px, gray lavender, up-left);
 */
/* stylelint-disable */
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;

  $width: nth($size, 1);
  $height: nth($size, length($size));

  $foreground-color: nth($color, 1);
  $background-color: transparent !default;
  @if (length($color) == 2) {
    $background-color: nth($color, 2);
  }

  @if ($direction == up) or
    ($direction == down) or
    ($direction == right) or
    ($direction == left)
  {
    $width: $width / 2;
    $height: if(length($size) > 1, $height, $height/2);

    /* stylelint-disable block-closing-brace-empty-line-before */
    @if $direction == up {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-bottom: $height solid $foreground-color;
    } @else if $direction == right {
      border-top: $width solid $background-color;
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;
    } @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;
    } @else if $direction == left {
      border-top: $width solid $background-color;
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
    }
    /* stylelint-enable block-closing-brace-empty-line-before, block-closing-brace-newline-after */
  } @else if ($direction == up-right) or ($direction == up-left) {
    border-top: $height solid $foreground-color;

    @if $direction == up-right {
      border-left: $width solid $background-color;
    } @else if $direction == up-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction == down-right {
      border-left: $width solid $background-color;
    } @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == inset-up) {
    border-width: $height $width;
    border-style: solid;
    border-color: $background-color $background-color $foreground-color;
  } @else if ($direction == inset-down) {
    border-width: $height $width;
    border-style: solid;
    border-color: $foreground-color $background-color $background-color;
  } @else if ($direction == inset-right) {
    border-width: $width $height;
    border-style: solid;
    border-color: $background-color
      $background-color
      $background-color
      $foreground-color;
  } @else if ($direction == inset-left) {
    border-width: $width $height;
    border-style: solid;
    border-color: $background-color
      $foreground-color
      $background-color
      $background-color;
  }
}
/* stylelint-enable */

/*-------------[FUNCTIONS]-------------*/
/*
 * returns pixel font size in rem
 */
@function rem($pixels) {
  @return $pixels / $base-font-size + rem;
}

/*
 * DEPRECATED - use rem() function above
 * when using em's, when dividing by the base font size, it multiplies it based on its parent, rather than the base font size
 * This function is therefore not quite correct.
 * Left it in for legacy sites, but needs to be phased our
 * returns pixel font size in em
 */
@function em($pixels) {
  @return $pixels / $base-font-size + em;
}
// scss-lint:enable all
