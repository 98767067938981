.case-study-filters {
  ul {
    margin: 40px auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    a {
      padding: 5px;
      margin: 5px;
      position: relative;

      &::after {
        content: '';
        left: 0;
        bottom: 5px;
        background: color(primary);
        width: 100%;
        height: 1px;
        position: absolute;
        opacity: 0;
        transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;
      }

      &:active,
      &:hover,
      &.-active {
        &::after {
          opacity: 1;
        }
      }

      &:active,
      &.-active {
        color: color(primary);
      }
    }
  }
}

.case-study-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
}

.case-study {
  width: calc(33.333% - 40px);
  margin: 20px;
  position: relative;

  &__inner {
    background: color(primary);
    color: white;
    padding: 40px;
    transition: transform 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 550px;
    will-change: transform;
  }

  @media (max-width: 1400px) {
    width: calc(50% - 40px);
  }

  @media (max-width: 750px) {
    width: calc(100% - 40px);
  }

  &:nth-child(3n - 1):not(.-no-bump),
  &.-bump-up {
    margin-top: -100px;
    margin-top: var(--ty);

    @media (max-width: 1400px) {
      margin-top: 20px;
    }
  }

  &__title {
    > * {
      margin: 0 0 20px;
      line-height: 1.125;
    }
  }

  p {
    line-height: 1.5;
    transform: translateY(100%);
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s $ease-out;
  }

  &__tags {
    line-height: 1.5;
    transform: translateY(0%);
    opacity: 1;
    transition: opacity 0.3s, transform 0.3s $ease-out;

    > .tag {
      font-family: $font-secondary;
      font-size: 16px;
      display: inline-block;
      padding-right: 5px;

      &::after {
        content: ',';
      }

      &:last-child {
        &::after {
          content: '';
        }
      }
    }
  }

  &__hover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: color(primary);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    will-change: opacity;
    padding: 40px;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__link {
    font-family: $font-secondary;
    font-size: 16px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    line-height: 1.5;
    margin: 0;

    span {
      transform: translateY(100%);
      transition: opacity 0.3s, transform 0.3s $ease-out;
    }

    &::after {
      content: '';
      width: 24px;
      height: 24px;
      display: block;
      background-size: 24px 24px;
      opacity: 0;
      transform: translateX(-100%);
      transition: opacity 0.3s, transform 0.3s $ease-out;
      /* stylelint-disable string-quotes */
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 24 24'%3E%3Cpath d='M22 12l-4-4v3H3v2h15v3z'/%3E%3C/svg%3E");
      /* stylelint-enable string-quotes */
    }
  }

  &:hover {
    .case-study__inner {
      transform: scale(1.05);
    }

    .case-study__hover {
      opacity: 1;
      pointer-events: all;
      color: white;

      p {
        opacity: 1;
        transform: translateY(0%);
      }
    }

    .case-study__link {
      span {
        opacity: 1;
        transform: translateY(0%);
      }

      &::after {
        opacity: 1;
        transform: translateX(0%);
      }
    }

    .case-study__tags {
      opacity: 0;
      transform: translateY(-100%);
    }
  }
}
