/**
 * Helper styles
 * e.g. .centered { margin-right: auto; margin-left: auto; }
 */

.position--relative {
  position: relative;
}

.position--absolute {
  position: absolute;
}

.text--center {
  text-align: center;
}

.centered {
  margin-right: auto;
  margin-left: auto;
}

/* Vertically centre something within an element by using this hack */
.display--table {
  display: table;
}
.vert--middle {
  display: table-cell;
  vertical-align: middle;
}

/*
 * add .img--full to the img parent
 * it will be full width for non-admins
 * normal width for admins, so the crop tool isn't broken
 */
.img--full {
  /* stylelint-disable selector-no-type */
  > img,
  > noscript > img {
    display: block;
    width: 100%;
    height: auto;
  }
  /* stylelint-enable selector-no-type */
}
