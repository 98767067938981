.company-intro {
  display: flex;
  justify-content: center;
  align-items: center;

  .site-container {
    width: 100%;
  }
}

.company-intro__content {
  position: relative;
  color: color(primary);

  opacity: 0;
  transform: translateY($fade-up-distance-mobile);
  transition: transform 0.6s $ease-out, opacity 0.6s;

  @media (min-width: 600px) {
    transform: translateY($fade-up-distance);
  }

  &.-in-view {
    opacity: 1;
    transform: none;
  }

  /* stylelint-disable selector-no-type */
  p {
    max-width: 1370px;
    position: relative;
    z-index: $z-low;
    font-family: $font-tertiary;
    margin-right: auto;
    margin-left: auto;

    strong {
      border-bottom: solid 1px color(primary-light);

      &:nth-child(1) {
        border-bottom: none;
      }
    }

    a {
      @include underline(
        transparent,
        rgba(color(primary), 0.3),
        color(primary)
      );
    }
  }
  /* stylelint-enable selector-no-type */
}
