/*doc
---
title: Fonts
name: fonts
category: fonts
---


<h3 class="styleguide-font -graphik">Graphik - Primary</h3>
<p class="styleguide-font -graphik">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur velit est, tempus vitae venenatis et, convallis sed risus. Donec vestibulum dui metus, et blandit purus placerat nec. Vivamus eget justo maximus turpis dignissim semper et at mi.</p>
<h3 class="styleguide-font -pressura">GT Pressura Mono - Secondary</h3>
<p class="styleguide-font -pressura">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur velit est, tempus vitae venenatis et, convallis sed risus. Donec vestibulum dui metus, et blandit purus placerat nec. Vivamus eget justo maximus turpis dignissim semper et at mi.</p>
<h3 class="styleguide-font -calluna">Calluna - Tertiary</h3>
<p class="styleguide-font -calluna">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur velit est, tempus vitae venenatis et, convallis sed risus. Donec vestibulum dui metus, et blandit purus placerat nec. Vivamus eget justo maximus turpis dignissim semper et at mi.</p>

*/

@include font-face-parallax('Graphik', '../fonts/Graphik-Regular-Web');
@include font-face-parallax('Graphik', '../fonts/Graphik-RegularItalic-Web', normal, italic);

@include font-face-parallax('GT-Pressura-Mono', '../fonts/GT-Pressura-Mono-Regular');
@include font-face-parallax('Calluna', '../fonts/Calluna');
@include font-face-parallax('Calluna', '../fonts/Calluna-Bold', bold);

@include font-face-parallax('Eczar', '../fonts/eczar-v4-latin-700', bold, normal, svg);
