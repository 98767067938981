.dropdown {
  height: 45px;
  display: inline-block;
  position: relative;

  &.-is-select {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }

    &.-on-purple {
      &::after {
        @include triangle(10px, color(white), down);
      }
    }
    &.-on-white {
      &::after {
        @include triangle(10px, color('primary'), down);
      }
    }
  }

  &.-on-purple {
    .dropdown__label {
      color: color(white);
      border-color: rgba(color(white), 0.5);

      &::before {
        background-color: color(white);
      }
      &::after {
        border-color: color(white) transparent transparent;
      }
    }
    .dropdown__list {
      background-color: color(primary-light);
    }
    .dropdown__link {
      color: color(white);

      &:hover,
      &.-selected {
        color: color(primary);
      }
    }
  }
  &.-on-white {
    .dropdown__label {
      border-color: color(lilywhite);
    }
  }
}

.dropdown__select {
  position: relative;

  &.-bare {
    max-width: 225px;
    width: 100%;
    height: 45px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    outline: none;
    appearance: none;
  }

  &.-on-purple {
    color: color(white);
    border-bottom: solid 1px rgba(color(white), 0.3);
  }
  &.-on-white {
    color: color('primary');
    border-bottom: solid 1px rgba(color('primary'), 0.3);
  }
}

.dropdown__container {
  display: inline-block;
  vertical-align: middle;
  width: 220px;
  max-width: 100%;
  text-align: left;
  line-height: 1;
  position: relative;
}

.dropdown__list {
  list-style: none;
  margin: 0;
  position: absolute;
  z-index: 5;
  top: -14px;
  left: -4444px;
  right: 4000px;
  background: color(primary);
  color: color(white);
  padding: 15px 23px;

  transform: translateY(20px);
  opacity: 0;
  transition: transform 0.4s $ease-out, opacity 0.4s, left 0s 0.4s,
    right 0s 0.4s;

  .dropdown.-active & {
    left: -23px;
    right: -23px;
    transform: none;
    opacity: 1;
    transition-delay: 0s, 0s, 0s, 0s;
  }
}

/* stylelint-disable selector-no-type, selector-no-qualifying-type */
.dropdown__label,
.dropdown__link {
  display: block;
  padding: 7px 0;
  color: inherit;
  appearance: none;
  background: none;
  border: 0;
  text-align: left;
}
/* stylelint-enable selector-no-type, selector-no-qualifying-type */
.dropdown__link {
  width: 100%;

  &:not(.-selected) {
    color: color(primary-light);
  }
  &:hover,
  &.-selected {
    color: color(white);
  }
}

/* stylelint-disable selector-no-type, selector-no-qualifying-type */
.dropdown__label {
  width: 100%;
  border-bottom: 1px solid color(lilywhite);
  padding: 0;
  position: relative;

  span {
    display: block;
    padding: 8px 30px 8px 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &::before {
    content: '';
    height: 1px;
    display: block;
    position: absolute;
    bottom: -1px;
    right: 0;
    left: 0;
    background: color(primary);
    transform-origin: left;
    transform: scaleX(0);
    transition: transform 0.3s linear;
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 0;
    pointer-events: none;
    border-style: solid;
    border-width: 6px 5px 0;
    border-color: $body-text transparent transparent;
  }

  &:hover,
  &:active,
  &:focus {
    outline: 0;

    &::before {
      transform: scaleX(1);
    }
  }
}
/* stylelint-enable selector-no-type, selector-no-qualifying-type */

.filter .dropdown {
  margin-left: 40px;
}
