.filter {
    margin-top: 40px;
    transform: translateY($fade-up-distance-mobile);
    opacity: 0;

    @media (min-width: 600px) {
        transform: translateY($fade-up-distance);
    }

    .hero-loaded.fonts-loaded & {
        animation: fadeup ($fade-duration - 0.2s) 0.6s $ease-out forwards;
    }

    .hero-loaded.fonts-loaded.landing & {
        animation-delay: 1.6s;
    }

    .caption {
        margin-right: 20px;
    }

    /* stylelint-disable selector-no-type */
    label {
        color: color(overcast);
    }
    /* stylelint-enable */
}

.filter__select {
    position: relative;
    display: inline-block;
    color: $body-text;
    margin-left: 20px;

    /* Dropdown arrow */
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -3px;
        right: 0;
        display: block;
        width: 0;
        height: 0;
        pointer-events: none;
        border-style: solid;
        border-width: 6px 5px 0;
        border-color: $body-text transparent transparent;
    }

    /* stylelint-disable selector-no-type */
    select {
        display: inline-block;
        width: 100%;
        height: 2em;
        margin: 0;
        padding: 0 30px 0 0;
        line-height: 2;
        color: $body-text;
        background: none;
        border: 0;
        border-bottom: 1px solid color(lilywhite);
        border-radius: 0;
        cursor: pointer;
        outline: 0;
        transition: border-color 0.2s;
        appearance: none;
    }

    /* Hide the arrow in IE10 and up */
    select::-ms-expand {
        display: none;
    }

    select:focus {
        border-color: color(primary);
    }

    select:focus:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }
    /* stylelint-enable */
}

/* Hover state */
/* Uncomment if you need it, but be aware of the sticky iOS states.
.select select:hover {
background-color: #ddd;
}
*/

/* Media query to target Firefox only */
@-moz-document url-prefix('') {
    /* Firefox hack to hide the arrow */
    /* stylelint-disable selector-no-type */
    .filter__select select {
        text-indent: 0.01px;
        text-overflow: '';
        padding-right: 1rem;
    }
    /* <option> elements inherit styles from <select>, so reset them. */
    .filter__select option {
        background-color: color(white);
    }
    /* stylelint-enable */
}

/* IE9 hack to hide the arrow */
@media screen and (min-width: 0\0) {
    /* stylelint-disable selector-no-type */
    .filter__select select {
        z-index: 1;
        padding: 0 30px 0 0;
    }
    .filter__select::after {
        z-index: 5;
    }
    .filter__select::before {
        position: absolute;
        top: 0;
        right: 30px;
        bottom: 1px;
        z-index: 2;
        content: '';
        display: block;
        width: 30px;
        background-color: color(white);
        pointer-events: none;
    }
    .filter__select select:hover,
    .filter__select select:focus,
    .filter__select select:active {
        color: $body-text;
        background: none;
    }
    /* stylelint-enable */
}
