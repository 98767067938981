/*doc
---
title: Text
name: text
category: text
---


<h4>Body</h4>
<p class="textarea">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur velit est, tempus vitae venenatis et, convallis sed risus. Donec vestibulum dui metus, et blandit purus placerat nec. Vivamus eget justo maximus turpis dignissim semper <a href="#">vestibulum dui metus</a> et at mi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur velit est, tempus vitae venenatis et, convallis sed risus. Donec vestibulum dui metus, et blandit purus placerat nec. Vivamus eget justo maximus turpis dignissim semper et at mi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur velit est, tempus vitae venenatis et, convallis sed risus. Donec vestibulum dui metus, et blandit purus placerat nec. Vivamus eget justo maximus turpis dignissim semper <a href="#">vestibulum dui metus</a> et at mi.</p>
<h4>Quote</h4>
<blockquote>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur velit est, tempus vitae venenatis et, convallis sed risus. Donec vestibulum dui metus, et blandit purus placerat nec. Vivamus eget justo maximus turpis dignissim semper et at mi.</p>
</blockquote>
<div class="blockquote-author">- Lorem Ipsum, Dolor</div>
<h4>Caption</h4>
<div class="caption">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>

*/

/* stylelint-disable selector-no-type */
.textarea {
    a:not(.btn) {
        @extend %textlink;
    }

    ul,
    ol {
        margin-left: 0;
        list-style: none;
    }

    ul li {
        position: relative;
        padding-left: 24px;

        &::before {
            content: '\2022';
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    ol {
        counter-reset: i;

        li {
            counter-increment: i;
            position: relative;
            padding-left: 24px;

            &::before {
                content: counter(i) '.';
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
        margin: $spacing 0;
        border: 0;
        border-top: 1px solid color(cloud);
    }

    .btn {
        margin-right: 20px;
    }
}
/* stylelint-enable selector-no-type */
