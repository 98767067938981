.events-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 57px;
  background-color: #fff;
  color: #333;
  border-bottom: 1px solid color(nimbus);
  transition: transform 0.4s $ease-out, opacity 0.4s $ease-out,
    height 0.4s $ease-out, border-bottom 0.4s $ease-out,
    background-color 0.4s $ease-out;
  transform: translateY(-103%) translateZ(0);
  opacity: 0;
  z-index: 100;

  &.active {
    height: 100vh;
    border-bottom: 1px solid color(primary);
    background-color: color(primary);
  }

  .body--events_index.-mobile-nav-active & {
    z-index: 99;
  }

  @media (max-width: 799px) {
    height: 39px;
  }

  @media (max-width: 580px) {
    height: 34px;
  }
}

.events-nav__share-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: color(primary);
  opacity: 0;
  transition: opacity 0.4s $ease-out, transform 0.4s $ease-out,
    z-index 0.4s $ease-out;
  overflow: hidden;
  transform: translateY(-700px) translateZ(0);
  z-index: -1;

  .events-nav.active & {
    opacity: 1;
    transform: translateY(0) translateZ(0);
    z-index: 1;
  }
}

.events-nav__share-overlay-inner {
  @include middle(bloody-both-innit, absolute);
  max-width: 680px;
  margin: 0 auto;
  top: 43%;

  @media (max-width: 949px) {
    top: 36%;
  }

  @media (max-width: 420px) {
    top: 30%;
  }
}

.events {
  &.-in-view.-active {
    & + .events-nav {
      transform: translateY(0) translateZ(0);
      opacity: 1;

      @media (max-width: 949px) {
        transform: translateY(80px) translateZ(0);
      }
    }
  }
}

.events-nav__list {
  @include clean-list;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;

  @media (max-width: 949px) {
    padding-left: 70px;
    padding-right: 70px;
  }

  @media (max-width: 580px) {
    padding: 0;
    margin-left: 0;
    margin-right: 60px;
    width: 100px;
    height: 100%;
    // display: block;
    float: right;
    cursor: pointer;

    // Removing for now
    display: none;
  }
}

.events-nav__item {
  padding-top: 9px;
  padding-bottom: 8px;
  width: 100%;
  text-align: center;
  cursor: pointer;

  button {
    border: 0;
    background: none;
    padding: 0;
  }

  span {
    display: inline-block;
    padding: 0 1px;
    position: relative;
    line-height: 1.9;
    pointer-events: none;

    &::after {
      content: '';
      background-color: #8b61da;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 0%;
      transition: width 0.4s $ease-out;
    }
  }

  &:hover {
    span {
      &::after {
        width: 100%;
      }
    }
  }

  @media (max-width: 799px) {
    padding-top: 3px;
    padding-bottom: 4px;
  }

  @media (max-width: 580px) {
    display: none;
    padding-top: 1px;
    padding-bottom: 1px;

    .events-nav.-mob-open & {
      position: static;
      display: block;
      background-color: #fff;
    }

    &:nth-child(even) {
      .events-nav.-mob-open & {
        background-color: #f0f0f0;
      }
    }
  }

  /* stylelint-disable declaration-no-important */
  &.-selected {
    // span {
    //     &::after {
    //         width: 100%;
    //     }
    // }

    @media (max-width: 580px) {
      display: block;
      background-color: color(primary) !important;
      color: #fff;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.events-nav__share-block {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 57px;
  width: 190px;
  background-color: color(nimbus);
  font-family: $font-secondary;
  font-size: rem(15px);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  z-index: 100;
  overflow: hidden;

  &:hover {
    background-color: color(primary);
  }

  .events-nav.active & {
    background-color: color(nimbus);
  }

  @media (max-width: 1800px) {
    width: 140px;
  }

  @media (max-width: 1200px) {
    width: 60px;
  }

  @media (max-width: 799px) {
    height: 39px;
  }

  @media (max-width: 580px) {
    height: 33px;
  }
}

.events-nav__share-icon,
.events-nav__close-icon {
  display: inline-block;
  width: 16px;
  margin-right: 10px;

  svg {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }

  @media (max-width: 1200px) {
    margin-right: 0;

    svg {
      width: 16px;
      height: 18px;
    }
  }
}

.events-nav__close-icon {
  width: 10px;

  svg {
    width: 10px;
    height: 10px;
  }
}

.events-nav__share-text {
  @media (max-width: 1200px) {
    display: none;
  }
}

.events-nav__share-state {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transform: translateY(100%);

  &.active {
    z-index: 102;
    opacity: 1;
    transform: translateY(0);
  }
}

.events-nav__share-state-inner {
  @include middle(bloody-both-innit, absolute);

  @media (max-width: 1200px) {
    height: 36px;
  }
}

.events-nav__share-overlay-heading {
  color: #fff;
  font-family: $font-eczar;
  text-align: center;
  font-size: rem(32px);
}

.events-nav__social-list {
  @include clean-list;
  @include cf;
  width: 692px;

  @media (max-width: 949px) {
    width: 302px;
  }

  @media (max-width: 420px) {
    width: 182px;
  }
}

.events-nav__social-item {
  fill: #fff;
  background-color: color(nimbus);
  display: block;
  float: left;
  width: 226px;
  height: 100px;
  transition: background-color 0.2s;
  cursor: pointer;

  svg {
    width: 40px;
    height: auto;
    margin: 0 auto;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
  }

  &:nth-child(2) {
    margin: 0 1px;
  }

  &:hover {
    background-color: color(primary-light);
  }

  * {
    pointer-events: none;
  }

  @media (max-width: 949px) {
    width: 100px;
    height: 60px;
  }

  @media (max-width: 420px) {
    width: 60px;
    height: 34px;
  }
}

.events-nav__social-item--facebook {
  svg {
    width: 56px;
  }

  @media (max-width: 949px) {
    svg {
      width: 40px;
    }
  }

  @media (max-width: 420px) {
    svg {
      width: 32px;
    }
  }
}

.events-nav__social-item--twitter {
  svg {
    width: 50px;
  }

  @media (max-width: 949px) {
    svg {
      width: 40px;
    }
  }

  @media (max-width: 420px) {
    svg {
      width: 36px;
    }
  }
}

.events-nav__social-item--linkedin {
  svg {
    width: 30px;
  }

  @media (max-width: 949px) {
    svg {
      width: 22px;
    }
  }

  @media (max-width: 420px) {
    svg {
      width: 20px;
    }
  }
}
