.expose__logo {
    position: relative;

    h1 {
        margin: 0 0 0 64px;
    }

    img {
        display: block;
        margin: 0 auto;
        transform: translateX(40px);
        opacity: 0;

        .fonts-loaded & {
            animation: exposelogo 0.7s 0.4s $ease-out forwards;
        }

        .landing.fonts-loaded & {
            animation-delay: 1.3s;
        }
    }
}

@keyframes exposelogo {
    from {
        opacity: 0;
        transform: translateX(40px);
    }
    to {
        opacity: 1;
        transform: none;
    }
}

.expose__video {
    &.-right > div {
        margin-left: auto;
    }
}

.expose__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -125px;
    margin-left: -216px;
    width: 230px;
    height: 230px;
}

.expose__aws {
    position: relative;
    max-width: 333px;

    &::before {
        content: '';
        display: block;
        padding-top: (151.78 / 392.35) * 100%;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media (max-width: 599px) {
        max-width: 260px;
    }

    @media (min-width: 1000px) {
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: 1500px) {
        max-width: 430px;
    }
}
