/**
 * Base Template bits
 **/

/* stylelint-disable selector-no-type, selector-no-qualifying-type */
html {
    height: 100%;
}

// Stops elements being visibly focused until the user hits tab
*:focus {
    outline: 0;
}

body {
    min-height: 100%;
    color: $body-text;
    background-color: color(white);
    font-size: rem(14px);
    transition: background-color 0.4s;

    @media (min-width: breakpoint(800)) {
        font-size: rem(18px);
    }

    &.-no-scroll,
    &.-split-modal-active,
    &.-mobile-nav-active {
        overflow: hidden;
    }
}
/* stylelint-enable selector-no-type, selector-no-qualifying-type */

[class^='cover-'] {
    position: fixed;
    z-index: -1;
    top: -100px;
    right: 0;
    bottom: -100px;
    left: 0;
    opacity: 0.01;
    transition: opacity 0.4s;
    will-change: opacity;
}

.cover-dark {
    background: color(primary-dark);

    .body--dark & {
        opacity: 0.99;
    }
}
.cover-primary {
    background: color(primary);

    .body--primary & {
        opacity: 0.99;
    }
}
.cover-nimbus {
    background: color(nimbus);

    .body--nimbus & {
        opacity: 0.99;
    }
}

/* stylelint-disable selector-no-type */
a {
    text-decoration: none;
    color: $body-text;
}

img {
    max-width: 100%;
}

address {
    font-style: normal;
    margin: 0;
}
/* stylelint-enable selector-no-type */

.expose-body {
    overflow: hidden;
    position: relative;
    z-index: 1;
    min-height: 100vh;
}

// .site-section.-seen > * {
//     transition: opacity 0.4s;
// }

// .-section-hidden > * {
//     opacity: 0;
// }


.wc-before::before {
    will-change: transform, opacity;
}
.wc-after::after {
    will-change: transform, opacity;
}


.bg-0,
.bg-1 {
    position: fixed;
    backface-visibility: hidden;
    z-index: -2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.01;
    transition: opacity 0.4s;

    &.-active {
        z-index: -1;
        opacity: 1;
        transition-delay: 0;
    }
}

.bg-white,
.bg-primary,
.bg-dark,
.bg-nimbus,
.bg-cloud,
.bg-shapes,
.bg-martinique {
    position: fixed;
    z-index: -2;
    backface-visibility: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0s 0.4s; /* stylelint-disable-line time-no-imperceptible */
}
.bg-white,
.bg-shapes {
    background-color: color(white);
}
.bg-shapes {
    background-image: url('../img/svg/shapes-bg.svg');
    background-size: 100% auto;
    background-position: top;
}
.bg-primary {
    background-color: color(primary);
}
.bg-dark {
    background-color: color(primary-dark);
}
.bg-nimbus {
    background-color: color(nimbus);
}
.bg-cloud {
    background-color: color(cloud);
}
.bg-martinique {
    background-color: color(martinique);
}

.body--dark,
.body--primary,
.body--nimbus,
.body--martinique {
    color: color(white);
}

.body--white .bg-white,
.body--primary .bg-primary,
.body--dark .bg-dark,
.body--nimbus .bg-nimbus,
.body--cloud .bg-cloud,
.body--shapes .bg-shapes,
.body--martinique .bg-martinique {
    z-index: -1;
    opacity: 1;
    transition: opacity 0.4s;
}

.body--home {
    background: color(primary-dark);

    .bg-white,
    .bg-primary,
    .bg-dark,
    .bg-nimbus,
    .bg-shapes {
        transition: opacity 0.4s;
    }
}

/* stylelint-disable declaration-no-important */
/* stylelint-disable selector-no-qualifying-type */
body.-page-loading {
    background: none;
    .bg-white,
    .bg-primary,
    .bg-dark,
    .bg-nimbus,
    .bg-shapes {
        opacity: 0 !important;
        transition: opacity 0s 0.4s !important;
    }
}
/* stylelint-enable declaration-no-important */
/* stylelint-enable selector-no-qualifying-type */

.-disabled {
    opacity: 0.5;
}
