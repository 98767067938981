%caption,
.caption {
    font-family: $font-secondary;
    font-size: rem(14px);

    @media (min-width: breakpoint(800)) {
        font-size: rem(16px);
    }

    /* stylelint-disable selector-no-qualifying-type */
    &.-primary {
        color: color(primary);
    }
    /* stylelint-enable selector-no-qualifying-type */
}
