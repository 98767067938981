/**
 * Team
 */
.team {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: flex-start;

  margin-top: 80px;
  margin-bottom: 60px;

  @media (min-width: 1000px) {
    margin-top: -13%;
  }
}

.person {
  flex: 0 0 auto;
  width: calc(100% - 40px);
  margin: 0 20px;
  position: relative;

  // 1 per row
  @media (max-width: 599px) {
    &:not(:first-child) {
      margin-top: 120px;
    }
  }

  // 2 per row
  @media (min-width: 600px) {
    width: calc(50% - 40px);
  }

  // 3 per row
  @media (min-width: 1100px) {
    width: calc(33.3333333% - 40px);
  }
}

.person__headshot {
  position: relative;
  background: color(primary-dark);

  &::before {
    content: '';
    display: block;
    padding-top: (650 / 430) * 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  // 2 per row
  @media (min-width: 600px) {
    .person:nth-child(2) & {
      margin-top: (370 / 430) * 100%;
    }

    .person:nth-child(2n + 3) & {
      margin-top: ((370 / 430) * -100%) + ((180 / 430) * 100%);
    }
    .person:nth-child(2n + 4) & {
      margin-top: (180 / 430) * 100%;
    }
  }

  // 3 per row
  @media (min-width: 1100px) {
    .person:nth-child(3) & {
      margin-top: (370 / 430) * 100% * 2;
    }

    .person:nth-child(3n + 4) & {
      margin-top: ((370 / 430) * -100% * 2) + ((180 / 430) * 100%);
    }
    .person:nth-child(3n + 5) & {
      margin-top: ((370 / 430) * -100%) + ((180 / 430) * 100%);
    }
    .person:nth-child(3n + 6) & {
      margin-top: (180 / 430) * 100%;
    }
  }
}

.person__info {
  position: absolute;
  bottom: -60px;
  right: 0;
  left: 40px;
  background: color(primary);
  color: color(white);
  padding: 26px 32px;

  @media (min-width: 1440px) {
    padding: 45px 52px;
  }
}

.person__name {
  margin: 0 0 14px;
}
.person__title {
  margin: 0;
  padding-left: 30px;
  position: relative;
  line-height: 1.2;

  @media (min-width: 800px) {
    padding-left: 45px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 9px;
    left: 0;
    width: 20px;
    height: 1px;
    background: rgba(white, 0.3);

    @media (min-width: 800px) {
      top: 11px;
      width: 30px;
    }

    @media (min-width: 1440px) {
      width: 30px;
    }
  }
}

/**
 * Clients
 */
.about-clients {
  text-align: center;

  .flickity-page-dots {
      display: none;
  }
}

.about-clients__intro {
  font-family: $font-tertiary;
  line-height: 1.4;

  /* stylelint-disable selector-no-type */
  p {
    margin-bottom: 0;
  }
  /* stylelint-enable selector-no-type */
}

.about-clients__grid {
  white-space: nowrap;
  margin-top: 70px;

  @media (min-width: breakpoint(700)) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: (breakpoint(700) - 1px)) {
    margin-left: -20px;
    margin-right: -20px;

    &::after {
      content: 'flickity'; // enable flickity
      display: none;
    }

    &:not(.flickity-enabled) {
      overflow: hidden;
    }
  }

  @media (min-width: breakpoint(800)) {
    margin-top: 110px;
  }

  @media (min-width: breakpoint(1200)) {
    margin-top: 140px;
  }

  .dot {
    width: 6px;
    height: 6px;
    background-color: color(primary-dark);
    opacity: 1;
    position: relative;
    margin: 0 4px;
    transition: background-color 0.2s;

    &.is-selected {
      background-color: color(white);
    }
  }
}

.about-clients__item {
  display: inline-block;
  width: 65%;

  > div {
    max-width: 180px;
    position: relative;
    margin: 0 auto;

    &::before {
      content: '';
      display: block;
      padding-top: 50%;
    }

    @media (min-width: 700px) {
      margin: 6% auto;
    }
    @media (min-width: 800px) {
      margin: 15% auto;
    }
  }

  /* stylelint-disable selector-no-type */
  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto !important; /* stylelint-disable-line declaration-no-important */
  }
  /* stylelint-enable selector-no-type */

  @media (min-width: breakpoint(700)) {
    flex: 0 0 50%;

    opacity: 0;
    transform: translateY($fade-up-distance-mobile);
    transition: transform $fade-duration $ease-out, opacity $fade-duration;

    @media (min-width: 600px) {
      transform: translateY($fade-up-distance);
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      padding-bottom: 0;
    }

    @for $i from 0 through 20 {
      &:nth-child(#{$i + 1}) {
        transition-delay: #{$i * 0.1s};
      }
    }

    .-in-view & {
      transform: none;
      opacity: 1;
    }
  }

  @media (min-width: breakpoint(800)) {
    flex: 0 0 33.333333333%;

    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
      padding-bottom: 0;
    }
  }

  @media (min-width: breakpoint(1000)) {
    .-work & {
      flex: 0 0 25%;

      &:nth-last-child(1),
      &:nth-last-child(2),
      &:nth-last-child(3),
      &:nth-last-child(4) {
        padding-bottom: 0;
      }
    }
  }
}

/**
 * Awards
 */
.about-awards__title {
  margin-bottom: 0;
}

.about-awards__list {
  @include clean-list;

  border-top: 1px solid color(primary);
  color: color(primary);
}

.about-awards__item {
  display: flex;
  justify-content: space-between;

  padding: 20px 0;
  border-bottom: 1px solid color(primary);

  .delete-link {
    display: inline-block;
    margin-left: 20px;
  }
}
