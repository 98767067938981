::selection {
    background: color(primary);
    color: color(white);
    text-shadow: none;
}

/* stylelint-disable selector-no-vendor-prefix */
::-moz-selection {
    background: color(primary);
    color: color(white);
    text-shadow: none;
}
/* stylelint-enable selector-no-vendor-prefix */
