/*
 * PADDING
 * ends: top and bottom
 * sides: left and right
 **/

.padding-top-hero,
.padding-ends-hero {
    padding-top: 20px;

    @media (min-width: 400px) {
        padding-top: 50px;
    }

    @media (min-width: 950px) {
        padding-top: 70px;
    }

    @media (min-width: 1800px) {
        padding-top: 100px;
    }
}

.padding-bottom-hero,
.padding-ends-hero {
    padding-bottom: 110px;

    @media (min-width: 568px) {
        padding-bottom: 128px;
    }
    @media (min-width: 768px) {
        padding-bottom: 182px;
    }
    @media (min-width: 950px) {
        padding-bottom: 220px;
    }
    @media (min-width: 1200px) {
        padding-bottom: 266px;
    }
    @media (min-width: 1800px) {
        padding-bottom: 288px;
    }
}

.padding-top-small,
.padding-ends-small {
    padding-top: 25px;

    @media (min-width: breakpoint(500)) {
        padding-top: 50px;
    }

    @media (min-width: breakpoint(800)) {
        padding-top: 100px;
    }

    @media (min-width: breakpoint(1200)) {
        padding-top: 150px;
    }
}

.padding-bottom-small,
.padding-ends-small {
    padding-bottom: 25px;

    @media (min-width: breakpoint(500)) {
        padding-bottom: 50px;
    }

    @media (min-width: breakpoint(800)) {
        padding-bottom: 100px;
    }

    @media (min-width: breakpoint(1200)) {
        padding-bottom: 150px;
    }
}

.padding-top-medium,
.padding-ends-medium {
    padding-top: 50px;

    @media (min-width: breakpoint(500)) {
        padding-top: 100px;
    }

    @media (min-width: breakpoint(800)) {
        padding-top: 150px;
    }

    @media (min-width: breakpoint(1200)) {
        padding-top: 200px;
    }
}

.padding-bottom-medium,
.padding-ends-medium {
    padding-bottom: 50px;

    @media (min-width: breakpoint(500)) {
        padding-bottom: 100px;
    }

    @media (min-width: breakpoint(800)) {
        padding-bottom: 150px;
    }

    @media (min-width: breakpoint(1200)) {
        padding-bottom: 200px;
    }
}

.padding-top-large,
.padding-ends-large {
    padding-top: 100px;

    @media (min-width: breakpoint(500)) {
        padding-top: 150px;
    }

    @media (min-width: breakpoint(800)) {
        padding-top: 250px;
    }

    @media (min-width: breakpoint(1200)) {
        padding-top: 350px;
    }
}

.padding-bottom-large,
.padding-ends-large {
    padding-bottom: 100px;

    @media (min-width: breakpoint(500)) {
        padding-bottom: 150px;
    }

    @media (min-width: breakpoint(800)) {
        padding-bottom: 250px;
    }

    @media (min-width: breakpoint(1200)) {
        padding-bottom: 350px;
    }
}

.padding-top-0 {
    /* stylelint-disable declaration-no-important */
    padding-top: 0 !important;
    /* stylelint-enable declaration-no-important */
}
