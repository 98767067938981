.hero {
  height: auto;
  min-height: calc(80vh - 89px);
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.-home {
    min-height: calc(100vh - 80px);
  }

  @media (min-width: 950px) {
    margin-top: 89px;

    &.-home {
      min-height: calc(100vh - 89px);
    }
  }

  @media (min-width: 1400px) {
    min-height: calc(80vh - 109px);
    margin-top: 109px;

    &.-home {
      min-height: calc(100vh - 109px);
    }
  }

  @media (min-width: 1800px) {
    min-height: calc(80vh - 139px);
    margin-top: 139px;

    &.-home {
      min-height: calc(100vh - 139px);
    }
  }

  &.-full {
    height: auto;
    min-height: calc(100vh - 89px);
    margin-top: 89px;

    @media (min-width: 1400px) {
      min-height: calc(100vh - 109px);
      margin-top: 109px;
    }
  }

  &.-events {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.hero__content {
  @include middle(xy, absolute);
  @include centered;

  padding: 20px;
  width: 100%;
  text-align: center;
}

.hero__title {
  color: color(white);
  margin: 0;
  visibility: hidden;

  .body--white &,
  .body--shapes & {
    color: color(nimbus);
  }

  div {
    overflow: hidden;
  }

  span {
    opacity: 0;

    // display: block;
    // transform: translateY(100%);
    // transition: transform 0.4s $ease-out;

    // .fonts-loaded.hero-loaded & {
    //     animation: h1 0.4s 0.6s $ease-out forwards;
    // }
  }

  /* stylelint-disable declaration-no-important */
  .fonts-loaded.hero-loaded.heading-ready & {
    visibility: visible;
    span {
      display: inline-block;
      vertical-align: top;
      animation: heading 0.7s 0.4s $ease-out forwards;
    }
  }
  .landing & {
    span {
      animation-delay: 1.3s !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.hero__pretitle {
  margin-bottom: 40px;
  line-height: 1;
  overflow: hidden;

  span {
    display: block;
    transform: translateY(105%);
    transition: transform 0.3s 0.4s $ease-out;

    .fonts-loaded.hero-loaded.heading-ready & {
      transform: none;
    }

    .landing & {
      transition-delay: 0.8s;
    }
  }
}

@keyframes heading {
  from {
    transform: translateY(100%);
    opacity: 1;
  }
  to {
    transform: none;
    opacity: 1;
  }
}
