// scss-lint:disable all
.styleguide-view {
    background-color: color(white);

    .site-container {
        display: flex;
    }
}

.styleguide-nav {
    flex: 0 1 20%;
}

.styleguide-nav__list {
    @include clean-list;
}

/*
.styleguide-nav__item {
}
*/

.styleguide-nav__link {
    &.-current,
    &:hover {
        color: color(primary);
    }
}

.styleguide-body {
    flex: 0 1 80%;

    .exampleOutput {
        margin-bottom: $spacing;

        .btn {
            vertical-align: top;
        }
    }
}

.styleguide-font {
    &.-pressura {
        font-family: $font-secondary;
    }

    &.-calluna {
        font-family: $font-tertiary;
    }
}


.styleguide-colours {
    @include clean-list;
}

.styleguide-colours__item {
    float: left;
    width: 25%;
    margin-bottom: 20px;

    &:nth-child(4n+1) {
        clear: left;
    }
}

.styleguide-colours__colour {
    height: 100px;

    @each $name, $hex in $colours {
        &.-#{$name} {
            background-color: $hex;
        }
    }
}
// scss-lint:enable all
