.blockquote,
%blockquote {
    font-family: $font-tertiary;
    color: color(primary);
}

/* stylelint-disable selector-no-type */
blockquote {
    @extend %blockquote;

    margin-bottom: $spacing * 2;

    &::before {
        @include background-svg('blockquote');

        content: '';
        display: block;
        width: 53px;
        height: 38px;
        margin: 0 auto ($spacing * 2);
    }
}
/* stylelint-enable selector-no-type */

.blockquote-author {
    font-family: $font-secondary;
    color: color(primary);
    text-align: center;
    font-size: rem(16px);
    margin-bottom: ($spacing * 2);

    &::after {
        @extend %squiggle;

        content: '';
        display: block;
        margin: ($spacing * 2) auto 0;
    }
}
