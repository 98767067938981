/* http://prismjs.com/download.html?themes=prism&languages=markup+css+clike+javascript+php */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

/* stylelint-disable selector-no-qualifying-type */
/* stylelint-disable font-family-name-quotes */
:not(pre) > code,
code[class*='language-'],
pre[class*='language-'] {
    color: black;
    background: none;
    text-shadow: 0 1px white;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;

    /* stylelint-disable property-no-vendor-prefix */
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    /* stylelint-enable property-no-vendor-prefix */
}
/* stylelint-enable font-family-name-quotes */

@media print {
    code[class*='language-'],
    pre[class*='language-'] {
        text-shadow: none;
    }
}

/* Code blocks */
pre[class*='language-'] {
    padding: 20px;
    margin: 0 0 $spacing;
    overflow: auto;
}

:not(pre) > code,
pre[class*='language-'] {
    background: color(whitesmoke);
}

/* Inline code */
:not(pre) > code {
    padding: 0.1em 0.3em;
    white-space: normal;
}
/* stylelint-enable selector-no-qualifying-type */

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: slategray;
}

.token.punctuation {
    color: #999;
}

.namespace {
    opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
    color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
    color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
    color: #a67f59;
    background: hsla(0, 0%, 100%, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
    color: #07a;
}

.token.function {
    color: #dd4a68;
}

.token.regex,
.token.important,
.token.variable {
    color: #e90;
}

.token.important,
.token.bold {
    font-weight: bold;
}
.token.italic {
    font-style: italic;
}

.token.entity {
    cursor: help;
}

