.grid {
  padding: 150px 0 50px;

  @media (min-width: breakpoint(800)) {
    padding: 200px 0 100px;
  }
}

.grid-inner {
  margin-top: -20px;
  transition: transform 0s $ease-out, opacity 0s;

  &.-hidden {
    opacity: 0;
    transform: translateY($fade-up-distance-mobile);
    transition-duration: $fade-duration, $fade-duration;

    @media (min-width: 600px) {
      transform: translateY($fade-up-distance);
    }
  }
}

.grid-item {
  float: left;
  padding: 20px;

  h2 {
    line-height: 1.46;
    margin: 0;
    transition: opacity 0.2s 0.2s;
  }

  p {
    line-height: 1.5;
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.4s;
  }

  .body--events_index & {
    h2 {
      line-height: 1.28;
      margin-bottom: 5%;
    }
  }
}

.grid-item--half-pad {
  padding: 10px;
}

.grid-item--visible {
  opacity: 1;
  transform: none;
}

.grid-item--spacer {
  display: none;

  @media (min-width: breakpoint(1100)) {
    display: block;
  }
}

.grid__inner {
  display: block;
  height: 0;
  padding-bottom: 100%;
  position: relative;

  &:hover {
    h2 {
      opacity: 0;
      /* stylelint-disable time-no-imperceptible */
      transition-delay: 0s;
      /* stylelint-enable time-no-imperceptible */
    }
    p {
      opacity: 1;
      transition-delay: 0.2s;
    }
  }

  @media (max-width: breakpoint(800)) {
    /* stylelint-disable declaration-no-important */
    &:not(.events-item__inner) {
      background: transparent !important;
    }
    /* stylelint-enable declaration-no-important */
  }
}

.grid__content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: transform 0.4s $ease-out, background 0.4s;

    .grid-item--white & {
      background: color(white);

      .body--white &,
      .body--shapes & {
        background: color(whitesmoke);
      }

      @include on-dark {
        background: rgba(0, 0, 0, 0.1);
      }
    }
    .grid-item--nimbus & {
      background: color(nimbus);
    }
    .grid-item--primary & {
      background: color(primary);
    }
    .grid-item--w2 & {
      @media (min-width: 600px) {
        background: #fff;
      }
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 12%;
    right: 18%;
    width: 24px;
    height: 24px;
    /* stylelint-disable string-quotes */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333333' viewBox='0 0 24 24'%3E%3Cpath d='M22 12l-4-4v3H3v2h15v3z'/%3E%3C/svg%3E");
    /* stylelint-enable string-quotes */
    background-size: 24px 24px;

    transform: translateX(-20px);
    opacity: 0;
    transition: transform 0.3s $ease-out, opacity 0.3s;

    .grid-item--primary &,
    .grid-item--nimbus:not(.grid-item--w2) & {
      /* stylelint-disable string-quotes */
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 24 24'%3E%3Cpath d='M22 12l-4-4v3H3v2h15v3z'/%3E%3C/svg%3E");
      /* stylelint-enable string-quotes */
    }

    @media (max-width: 599px) {
      opacity: 1;
      transform: none;
    }
  }

  .grid__inner:hover & {
    &::before {
      transform: scale(1.05);
    }
    &::after {
      transform: none;
      opacity: 1;
      transition-delay: 0.2s, 0.2s;
    }
  }

  .events-item & {
    text-align: center;

    &::before,
    &::after {
      display: none;
    }
  }
}

.grid-item--image {
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1.05);
    transition: transform 0.4s $ease-out;
  }

  .grid__inner {
    overflow: hidden;
    transition: transform 0.4s $ease-out;
    color: color(white);

    &::before {
      content: '@parall.ax';
      background: rgba(color(primary), 0.8)
        url('../img/svg/social-instagram.svg') center center no-repeat;
      background-size: 50px 50px;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: scale(1.05);
      transition: transform 0.4s $ease-out, opacity 0.4s;
      padding-bottom: 14.5%;
      padding-left: 20.5%;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      opacity: 0;
    }

    &:hover {
      transform: scale(1.05);

      &::before {
        transform: none;
        opacity: 1;
      }

      img {
        transform: none;
      }
    }
  }
}

.grid-item--w1 {
  width: 100%;

  @media (min-width: breakpoint(600)) {
    width: 50%;
  }

  @media (min-width: breakpoint(1100)) {
    width: 33.333333333%;
  }
}

.grid-item--w2 {
  width: 100%;

  @media (min-width: breakpoint(1100)) {
    width: 66.666666667%;
  }

  @media (min-width: 600px) {
    .grid__inner {
      background-color: color(nimbus);
      transition: background 0.4s $ease-out;

      background-size: cover;
      background-position: center;

      &:hover {
        background-color: darken(color(nimbus), 3%);
      }
    }
  }

  .grid__content {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (min-width: breakpoint(600)) {
      top: calc(25% + 10px);
      left: calc(25% + 10px);
      height: calc(50% - 20px);
      width: calc(50% - 20px);
    }
  }
}

@media (max-width: (breakpoint(600) - 1)) {
  margin-top: 15px;
}

.grid__body {
  position: relative;
  margin-top: 20%;

  padding: 0 18%;
  transition: transform 0.4s $ease-out;

  .grid-item--primary &,
  .grid-item--nimbus:not(.grid-item--w2) & {
    color: #fff;
  }

  .grid-item--white & {
    @include on-dark {
      color: color(white);
    }
  }

  .grid-item--w2 & {
    @media (min-width: 600px) {
      color: color(nimbus);
    }
  }
}

.grid__heading {
  font-size: rem(28px);
  text-transform: capitalize;

  @media (max-width: 1399px) {
    // to match a min-height 1400 elsewhere
    font-size: rem(28px);
  }
  @media (max-width: 1320px) {
    font-size: rem(26px);
  }
  @media (max-width: 1260px) {
    font-size: rem(20px);
  }
}

.grid__network {
  max-width: 51%; // left + right padding = 36%, remove another 13% to give the hover arrow effect some room
  width: 100%;
  font-family: $font-secondary;
  color: rgba(#fff, 0.5);
  line-height: 1.55;
  font-size: rem(16px);
  position: absolute;
  bottom: 12%;
  left: 18%;

  .grid-item--white & {
    color: rgba(#000, 0.3);

    @include on-dark {
      color: rgba(#fff, 0.3);
    }
  }

  .grid-item--w2 & {
    @media (min-width: 600px) {
      color: rgba(#000, 0.3);

      @include on-dark {
        color: rgba(#fff, 0.3);
      }
    }
  }

  @media (max-width: 1520px) {
    font-size: rem(14px);
  }
  @media (max-width: 1260px) {
    font-size: rem(13px);
  }
}

.grid__social-icon {
  @include middle(xy, absolute);

  display: block;
  width: 30px;
  height: 30px;
}

.grid__load-more {
  margin-top: 90px;
}
