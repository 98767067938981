.progress-bar {
  background-color: color(pastel);
  position: fixed;
  top: 0;
  z-index: 1;
  transition: transform 0.2s;

  > div {
    background-color: color(primary);
  }
}

.progress-bar--horizontal {
  left: 0;
  right: 0;
  transform: translateY(-100%);

  &.-visible {
    transform: translateY(0);
  }

  > div {
    width: 0;
    height: 4px;
  }
}

.progress-bar--vertical {
  left: 270px;
  bottom: 0;
  transform: translateX(0);
  z-index: 2;

  &.-visible {
    transform: translateX(100%);
  }

  > div {
    width: 4px;
    height: 0;
  }

  @media (max-width: 1799px) {
    left: 190px;
  }

  @media (max-width: 1199px) {
    left: 110px;
  }

  @media (max-width: 859px) {
    left: 0;
  }

  @media (max-width: 739px) {
    display: none;
  }
}
