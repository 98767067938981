.article__elements {
  @include clean-list;
  max-width: 100vw;
  overflow: hidden;
}

.article__element {
  margin-bottom: $spacing * 2;
  position: relative;

  @media (min-width: breakpoint(800)) {
    margin-bottom: $spacing * 4;
  }

  &.-image .site-container {
    @media (max-width: 949px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.-news-image-landscape .site-container,
  &.-embed .site-container {
    @media (max-width: 949px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.-quote {
    text-align: center;
  }
}

.article__columns {
  @media (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}

.article__column {
  @extend %trim;
  margin-left: 20px;
  margin-right: 20px;

  &.-text {
    flex: 0 0 auto;
    width: calc(100% - 40px);
    min-width: 0;

    @media (min-width: 1000px) {
      width: calc(50% - 40px);
    }

    @media (min-width: 1400px) {
      width: calc(33.3333333% - 40px);

      &.-half {
        width: calc(50% - 40px);
      }
    }
  }

  &.-image {
    flex: 0 0 auto;
    width: calc(100% + 20px);

    @media (max-width: 999px) {
      margin-left: -10px;
    }

    @media (min-width: 1000px) {
      width: calc(50% - 40px);
    }
  }

  article & {
    @media (min-width: 1000px) {
      &.-text.-left {
        margin-left: auto;
      }
      &.-text.-right {
        margin-right: auto;
      }
    }
  }
}

.article__columns--swap {
  .article__column.-left {
    @media (min-width: 1000px) {
      order: -1;
    }
  }
  .article__column.-right {
    margin-bottom: $spacing;
    @media (min-width: 1000px) {
      margin-bottom: 0;
    }
  }
}

.article__columns:not(.article__columns--swap) .article__column.-left {
  margin-bottom: $spacing;
  @media (min-width: 1000px) {
    margin-bottom: 0;
  }
}

.article-element__delete {
  display: block;
  position: absolute;
  z-index: $z-header;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  text-indent: -9999px;

  @include background-svg('element-delete');
}

.full-width-image {
  @media (min-width: 950px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.iframe-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;

  /* stylelint-disable selector-no-type */
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  /* stylelint-enable */
}

.article iframe {
  background: color(primary);
  border: 0;
}

.article .article__element.-textarea .max-width-sm {
  max-width: 940px !important; /* stylelint-disable-line */
}
