.product__image {
  position: relative;
  width: 50%;
  overflow: hidden;

  transition: transform 0.4s $ease-out, opacity 0.4s;

  transform: translateY($fade-up-distance-mobile);
  opacity: 0;

  @media (min-width: 600px) {
    transform: translateY($fade-up-distance);
  }

  &::before {
    content: '';
    display: block;
    padding-top: (800 / 660) * 100%;
  }

  /* stylelint-disable selector-no-type */
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    transform: scale(1.05);
    transition: transform 0.4s $ease-out;
  }
  /* stylelint-enable selector-no-type */
}

.product--left .product__image {
  margin-right: auto;
}
.product--right .product__image {
  margin-left: auto;
}
.product.-in-view .product__image {
  opacity: 0.3;
  transform: none;
}

.product {
  text-align: right;
  line-height: 1;

  &:not(:first-child) {
    margin-top: 120px;
  }

  /* stylelint-disable selector-no-type */
  a {
    display: block;
    position: relative;
    color: color(white);
  }

  &.-settled {
    a:hover {
      /* stylelint-disable selector-max-specificity */
      .product__image {
        transform: scale(1.05);
        opacity: 0.7;

        img {
          transform: none;
        }
      }
      /* stylelint-enable selector-max-specificity */
    }
  }
  /* stylelint-enable selector-no-type */
}

.product__main {
  position: absolute;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.product--left .product__main {
  left: 40%;
}
.product--right .product__main {
  right: 40%;
}

.product__title {
  line-height: 1;
  margin: 0;
  font-size: rem(120px);
  overflow: hidden;

  /* stylelint-disable selector-no-type, time-no-imperceptible */
  span {
    display: inline-block;
    transform: translateY(100%);
    transition: transform 0.6s 0.1s $ease-out;
  }
  /* stylelint-enable time-no-imperceptible */
}
.product.-in-view .product__title span {
  transform: none;
}
/* stylelint-enable selector-no-type */

/* stylelint-disable no-descending-specificity */
.product__desc {
  order: -1;
  margin: 0;
  position: relative;
  line-height: 1.25;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    margin-left: 14px;
    width: 40px;
    height: 1px;
    background: rgba(color(white), 0.4);

    transform-origin: 100% 50%;
    transform: scaleX(0);

    /* stylelint-disable time-no-imperceptible */
    transition: transform 0.6s 0.1s $ease-out; /* stylelint complaining about the 0.1s */
    /* stylelint-enable time-no-imperceptible */

    .product.-in-view & {
      transform: none;
    }
  }

  /* stylelint-disable selector-no-type */
  > span {
    display: inline-block;
    overflow: hidden;

    span {
      display: inline-block;
      transform: translateY(100%);
      transition: transform 0.6s 0.3s $ease-out;

      .product.-in-view & {
        transform: none;
      }
    }
  }
  /* stylelint-enable selector-no-type */
}
/* stylelint-enable no-descending-specificity */

/* stylelint-disable no-descending-specificity */
.product__more {
  position: relative;
  margin: 25px 0 0;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: 1px;
    height: 7px;
    z-index: 0;
    background: color(primary);

    transform-origin: 0 50%;
    transform: scaleX(0);
    transition: transform 0.6s 0.7s $ease-out;

    .product.-in-view & {
      transform: none;
    }
  }

  /* stylelint-disable selector-no-type */
  span {
    display: inline-block;
    position: relative;
    z-index: 1;
    transform: translateY(100%);
    transition: transform 0.6s 0.5s $ease-out;
  }
}
.product.-in-view .product__more span {
  transform: none;
}
/* stylelint-enable selector-no-type, no-descending-specificity */
