.site-header {
  position: relative;
  z-index: $z-header;
  backface-visibility: hidden;
}

.site-logo {
  display: block;
  position: fixed;
  z-index: $z-header;
  top: 30px;
  left: 30px;
  width: 135px;
  height: 20px;

  opacity: 0;
  transition: opacity 0.6s 0.4s;

  .body--home & {
    transition-delay: 0.4s + 1s;
  }

  .fonts-loaded.hero-loaded &,
  body:not(.landing) & {
    opacity: 1;
  }

  path {
    transition: fill 0.4s;
  }

  /* stylelint-disable selector-no-type */
  .body--white:not(.-mobile-nav-active) &,
  .body--cloud:not(.-mobile-nav-active) &,
  .body--shapes:not(.-mobile-nav-active) &,
  .-page-loading & {
    path {
      fill: color(primary);
    }
  }

  .body--martinique:not(.-mobile-nav-active) &,
  .-page-loading & {
    path {
      fill: color(white);
    }
  }
  /* stylelint-enable */

  /* stylelint-disable selector-no-qualifying-type */
  body.-split-modal-active:not(.-split-modal-leaving) & {
    path {
      transition-delay: 0.3s;
      fill: color(primary);
    }
  }

  body.-split-modal-leaving & {
    path {
      transition-delay: 0.6s;
    }
  }
  /* stylelint-enable selector-no-qualifying-type */

  @media (min-width: 950px) {
    top: 180px;
    left: 40px;
    transform: rotate(-90deg);
    transform-origin: top left;
  }

  @media (min-width: 1200px) {
    top: 220px;
    left: 80px;
  }

  @media (min-width: 1800px) {
    top: 250px;
    left: 120px;
  }

  @media (min-width: 2120px) {
    left: calc((50% - 800px - 20px) / 2);
  }

  .body--events_index & {
    @media (min-width: 950px) {
      top: 224px;
    }

    @media (min-width: 1800px) {
      top: 250px;
    }
  }
}
.body--contact,
// .body--case_studies_index,
.body--products_index {
  .site-footer {
    display: none;
  }
}
.body--contact {
  .site-nav {
    display: none;
  }
}

.site-header__email {
  /* stylelint-disable declaration-block-no-duplicate-properties */
  display: inline-block;
  /* stylelint-enable declaration-block-no-duplicate-properties */
  position: fixed;
  bottom: 76px;
  right: -22px;
  transform: translateY(-50%) rotate(90deg);

  backface-visibility: hidden;

  @include on-dark {
    color: color(white);
  }

  @include on-light {
    color: color(primary);
  }

  font-size: rem(16px);

  opacity: 0;
  transition: opacity 0.6s (1.2s + 1s);

  .fonts-loaded.hero-loaded &,
  body:not(.landing) & {
    opacity: 1;
  }

  @media (min-width: 1200px) {
    right: 18px;
    bottom: 119px;
  }

  @media (min-width: 1800px) {
    right: 57px;
  }

  @media (min-width: 2120px) {
    right: calc((50% - 800px - 149px) / 2);
  }

  .body--white &,
  .body--cloud &,
  .body--shapes &,
  .-page-loading & {
    color: color(primary);
  }

  .body--case_studies_index &,
  .body--products_index &,
  .body--events_index & {
    color: color(white);
  }

  &::after {
    display: none;
  }

  @media (max-width: 949px) {
    right: -31px;
    bottom: 88px;
    color: white !important; /* stylelint-disable-line */
    opacity: 0 !important; /* stylelint-disable-line */
    pointer-events: none;
    transition: opacity 0.2s;

    .-mobile-nav-active & {
      opacity: 1 !important; /* stylelint-disable-line */
      pointer-events: auto;
      transition: opacity 0.4s 0.3s;
    }
  }
  @media (max-width: 799px) {
    right: -26px;
  }
}
.site-header__email--hidden {
  display: none;
}

.header-social {
  position: fixed;
  z-index: $z-header;
  bottom: 35px;
  left: 28px;
  margin: 0;
  display: none;

  backface-visibility: hidden;

  /* stylelint-disable selector-no-type */
  .social-icon__icon {
    transition: fill 0.4s;

    .body--white &,
    .body--cloud &,
    .body--shapes &,
    .-page-loading & {
      fill: color(primary);
    }

    .body--martinique & {
      fill: color(white);
    }

    /* stylelint-disable selector-no-qualifying-type */
    body.-split-modal-active:not(.-split-modal-leaving) & {
      transition-delay: 0.2s;
      fill: color(primary);
    }

    body.-split-modal-leaving & {
      transition-delay: 0.6s;
    }
    /* stylelint-enable selector-no-qualifying-type */
  }
  /* stylelint-enable selector-no-type */

  .social-icon__ring circle {
    .body--white &,
    .body--cloud &,
    .body--shapes & {
      stroke: color(primary);
    }
    /* stylelint-disable selector-no-qualifying-type */
    body.-split-modal-active:not(.-split-modal-leaving) & {
      stroke: color(primary);
    }
    /* stylelint-enable selector-no-qualifying-type */
  }

  @media (min-width: 950px) and (min-height: 600px) {
    display: block;
  }

  @media (min-width: 1200px) {
    left: 68px;
    bottom: 79px;
  }

  @media (min-width: 1800px) {
    left: 108px;
  }

  @media (min-width: 2120px) {
    left: calc((50% - 800px - 43px) / 2);
  }
}

/**
 * Light background version
 */
.body--white,
.body--cloud,
.body--shapes {
  .site-header__btn {
    box-shadow: inset 0 0 0 2px rgba(color(primary), 0.3);
    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 50%,
      color(primary) 50%,
      color(primary) 100%
    );
    color: color(primary);

    &:hover {
      color: color(white);
    }
  }

  .site-nav__link {
    @media (min-width: 950px) {
      color: color(nimbus);
    }
  }

  /* stylelint-disable selector-max-specificity */
  .site-nav:not(.-in-v):not(.-out-v) .site-nav__link {
    @media (min-width: 950px) {
      &.-contact {
        color: color(primary);
        box-shadow: inset 0 0 0 2px rgba(color(primary), 0.3);
        background-image: linear-gradient(
          to bottom,
          transparent 0%,
          transparent 50%,
          color(primary) 50%,
          color(primary) 100%
        );

        &:hover,
        &:focus {
          box-shadow: inset 0 0 0 2px color(primary);
        }

        &:hover {
          color: color(white);
        }
      }
    }
  }
  /* stylelint-enable selector-max-specificity */

  .site-nav__number {
    color: color(white);
  }
}
