.site-container {
    @include centered;

    padding-left: 10px;
    padding-right: 10px;
    max-width: ((1600 + 30 + 30) + px);

    &.-skew-left {
        // The Parallax logo is horizontal & in nav bar below 768px
        // Social icons are still on the right though so create more space for those
        @media (max-width: 767px) {
            padding-left: 40px;
            padding-right: 130px;
        }
    }

    &.-max-width-xs {
        max-width: ((590 + 30 + 30) + px);
    }
    &.-max-width-small {
        max-width: ((760 + 30 + 30) + px);
    }
    &.-max-width-medium {
        max-width: ((1440 + 30 + 30) + px);
    }

    @media (min-width: 950px) {
        padding-left: 80px;
        padding-right: 80px;
        max-width: ((1600 + 100 + 100) + px);

        &.-max-width-xs {
            max-width: ((590 + 100 + 100) + px);
        }
        &.-max-width-small {
            max-width: ((760 + 100 + 100) + px);
        }
        &.-max-width-medium {
            max-width: ((1440 + 100 + 100) + px);
        }
    }

    @media (min-width: 1200px) {
        padding-left: 160px;
        padding-right: 160px;
        max-width: ((1600 + 180 + 180) + px);

        &.-max-width-xs {
            max-width: ((590 + 180 + 180) + px);
        }
        &.-max-width-small {
            max-width: ((760 + 180 + 180) + px);
        }
        &.-max-width-medium {
            max-width: ((1440 + 180 + 180) + px);
        }
    }

    @media (min-width: 1800px) {
        padding-left: 240px;
        padding-right: 240px;
        max-width: ((1600 + 260 + 260) + px);

        &.-max-width-xs {
            max-width: ((590 + 260 + 260) + px);
        }
        &.-max-width-small {
            max-width: ((760 + 260 + 260) + px);
        }
        &.-max-width-medium {
            max-width: ((1440 + 260 + 260) + px);
        }
    }
}

.max-width-sm {
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: 1400px) {
        width: 66.6666666%;
    }
}
