// move this into hybrid?
// in the usual responsive-text mixin, it does max-width and min-width queries around on sizes above and below
// the max-width and min-wdith. Only show max-width if you need to show max-width
@mixin responsive-text-grid($min-width, $max-width, $min-size, $max-size, $show-max:true) {
    $min-width-value: ($max-size - $min-size) / ($max-width - $min-width);
    $vw-value: (($max-size - $min-size) / ($max-width - $min-width) * 100) + vw;

    @media (min-width: $min-width) {
        /* stylelint-disable declaration-block-no-duplicate-properties */
        /* file: global */
        font-size: $max-size - ($min-size / 2); // fallback
        font-size: calc((#{$min-size} - #{$min-width} * #{$min-width-value}) + #{$vw-value});
        /* stylelint-enable declaration-block-no-duplicate-properties */
    }

    @if $show-max {
        @media (min-width: $max-width) {
            /* file: global */
            font-size: $max-size;
        }
    }
}



/* usual mixin included TTF and SVG, which we don't have for these fonts */
/* we only have woff, woff2 and eot */
/* added parameter to include svg font*/

/* stylelint-disable value-list-comma-space-after, indentation, block-closing-brace-newline-after */
@mixin font-face-parallax($font-family, $file-path, $weight: normal, $style: normal, $svg: false) {
    @font-face {
        font-family: $font-family;
        font-weight: $weight;
        font-style: $style;
        src: url('#{$file-path}.eot');
        @if $svg == 'false' {
            src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
             url('#{$file-path}.woff2')      format('woff2'),
             url('#{$file-path}.woff')       format('woff');
        } @else {
            src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
             url('#{$file-path}.woff2')      format('woff2'),
             url('#{$file-path}.woff')       format('woff'),
             url('#{$file-path}.svg#Eczar')  format('svg');
        }
    }
}
/* stylelint-enable value-list-comma-space-after, indentation, block-closing-brace-newline-after */


// kinda link grunticon but but uses the svgmin gulp stuff
@mixin background-svg($file) {
    background-image: url('../img/svg/#{$file}.svg');
    background-repeat: no-repeat;
}


@mixin underline($background: #fff, $color: #000, $hovercolor: #000, $position: 92%) {
    color: inherit;
    text-decoration: none;
    background: linear-gradient($background, $background), linear-gradient($background, $background), linear-gradient($color, $color), linear-gradient($hovercolor, $hovercolor);
    background-size: 0.05em 1px, 0.05em 1px, 1px 1px, 0 1px;
    background-repeat: no-repeat, no-repeat, repeat-x, no-repeat;
    //text-shadow: 0.03em 0 $background, -0.03em 0 $background, 0 0.03em $background, 0 -0.03em $background, 0.06em 0 $background, -0.06em 0 $background, 0.09em 0 $background, -0.09em 0 $background, 0.12em 0 $background, -0.12em 0 $background, 0.15em 0 $background, -0.15em 0 $background;
    background-position-y: $position, $position, $position, $position;
    background-position-x: 0%, 100%, 0%, 0%;

    transition: background-size 0.2s linear;

    &:hover {
        background-size: 0.05em 1px, 0.05em 1px, 1px 1px, 100% 1px;
    }
}

@mixin on-dark {
    .body--dark &,
    .body--primary &,
    .body--nimbus &,
    .body--martinique &,
    .body--home & {
        @content;
    }
}

@mixin on-light {
    .body--white &,
    .body--shapes &,
    .body--cloud & {
        @content;
    }
}
