/**
 * If 2 or more elements use the same SVG background, and it's 'above the fold', add it here,
 * and then use @extend to add it to your element
 * It'll reduce the filesize of the compiled CSS file
 **/

%squiggle {
    @include background-svg('squiggle');

    width: 79px;
    height: 8px;
}
