/**
* Subscribe form
*/
.subscribe {
  font-size: 0;
  position: relative;
  display: inline-block;
  vertical-align: top;

  .site-main & {
    opacity: 0;
  }
  .hero-loaded.fonts-loaded .site-main & {
    animation: fadeup 0.5s 1.8s cubic-bezier(0.42, 0.01, 0.23, 1) forwards;
  }

  > * {
    display: inline-block;
    vertical-align: top;
  }

  label {
    position: absolute;
    top: 0;
    left: 31px;
    line-height: 80px;
    cursor: text;
    transition: opacity 0.2s;
    font-size: 14px;

    @media (max-width: 767px) {
      left: 21px;
      line-height: 60px;
      font-size: 10px;
    }
  }

  input,
  button {
    appearance: none;
    background: none;
    border: 2px solid #655f73;
    border-radius: 0;
    height: 80px;
    padding: 0;
    transition: border 0.2s;

    @media (max-width: 767px) {
      height: 60px;
    }
  }

  input {
    width: 268px;
    padding: 0 30px;
    font-size: 16px;

    @media (max-width: 767px) {
      width: 198px;
      padding: 0 20px;
    }

    &:focus {
      outline: 0;
    }
  }

  button {
    border-left: 0;
    width: 79px;

    @media (max-width: 767px) {
      width: 59px;
    }

    div {
      margin: auto;
      /* stylelint-disable string-quotes */
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' viewBox='0 0 24 24'%3E%3Cpath d='M22 12l-4-4v3H3v2h15v3z'/%3E%3C/svg%3E")
        center center no-repeat;
      /* stylelint-enable string-quotes */
      background-size: cover;
      width: 24px;
      height: 24px;
      transition: transform 0.3s;

      .site-footer & {
        /* stylelint-disable string-quotes */
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 24 24'%3E%3Cpath d='M22 12l-4-4v3H3v2h15v3z'/%3E%3C/svg%3E");
        /* stylelint-enable string-quotes */
      }
    }

    &:hover {
      div {
        transform: translateX(5px);
      }
    }
  }

  &.-active,
  &.-has-text {
    label {
      pointer-events: none;
    }
  }

  &.-active {
    label {
      opacity: 0.5;
    }
    input,
    button {
      border-color: color(primary);
      .site-footer & {
        border-color: #fff;
      }
    }
  }

  &.-has-text {
    label {
      opacity: 0;
      transition: none;
    }
  }
}

.body--subscribe {
  .intro {
    opacity: 0;
  }
  .site-section__squiggle {
    margin-top: 0;
  }

  &.hero-loaded.fonts-loaded {
    .intro {
      animation: fadeup 0.5s 1.6s cubic-bezier(0.42, 0.01, 0.23, 1) forwards;
    }
  }
}
