// SCSS Variables
@import 'lib/vars';

// SCSS Mixins
@import 'lib/mixins';

// CSS Normalize
@import '../bower_components/inuit-normalize.css/generic.normalize';

// Hybrid
@import 'hybrid/hybrid';


// Site Skin
/* Patterns */
@import 'patterns/helper'; // Keep this first for em() function to be available to others
@import 'patterns/fonts';
@import 'patterns/headings';
@import 'patterns/template';
@import 'patterns/sections';
@import 'patterns/containers';
@import 'patterns/padding';
@import 'patterns/buttons';
@import 'patterns/blockquote';
@import 'patterns/caption';
@import 'patterns/code';
@import 'patterns/textarea';
@import 'patterns/forms';
@import 'patterns/columns';
@import 'patterns/tables';
@import 'patterns/shared_svg';

/* Components */
@import 'components/social_icons';
@import 'components/header';
@import 'components/company_intro';
@import 'components/nav';
@import 'components/hero';
@import 'components/grid';
@import 'components/footer';
@import 'components/article';
@import 'components/admin';
@import 'components/flickity';
@import 'components/phones';
@import 'components/products';
@import 'components/progress_bar';
@import 'components/filter';
@import 'components/dropdown';
@import 'components/shapes';
@import 'components/work';
@import 'components/work_legacy';
@import 'components/icon_list';
@import 'components/loader';
@import 'components/split_modal';
@import 'components/scroller_page';
@import 'components/block_image';
@import 'components/events';
@import 'components/events_nav';
@import 'components/subscribe';
@import 'components/careers-collage';

/* Top-level site structure */
@import 'ui/home';
@import 'ui/news_article_view';
@import 'ui/service_view';
@import 'ui/case_study';
@import 'ui/about';
@import 'ui/expose';
@import 'ui/jspdf';
@import 'ui/404';


@import 'ui/styleguide';



@import 'patterns/animations';
